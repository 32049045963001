import React, { useState, useRef } from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup, Container, Image, CloseButton } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useHistory, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import { Post, Get } from '@app/utils/httpProvider';
import { POST_API, CATEGORY_API, BaseGatewayImage, FILE_MANAGER_API } from '@app/utils/apiUrl';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Editor from '@app/components/editor/Editor';
import SelectTree from '@app/components/select-tree/SelectTree';
import { getToken, dateTimeToUTCRequest, getInfomationAccountFromToken } from '@app/utils/helper';
import axios from 'axios';
import {
    messageError,
    messageToast,
    acceptImage,
    status200,
    postStatus,
    dataTypeCategory,
    DATE_TIME_REACT_FORMAT,
    objectStatusPost,
    styleSelectDefault,
    styleSelectError,
    role,
} from '@app/utils/define';
import ReactSpinner from '@app/components/reactSpinner/ReactSpinner';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const schema = yup.object().shape({
    title: yup.string().required(messageError.emptyMessage),
    imageThumbnail: yup.string().required(messageError.emptyMessage),
    postStatus: yup.object().required(messageError.emptyMessage),
    categoryId: yup.string().required(messageError.emptyMessage),
    listImageFromik: yup.array().min(1, messageError.emptyMessage)
});

const LibraryImageAdd = () => {
    const history = useHistory();
    const inputUpload = useRef();
    const [isLoadingAdd, setIsLoadingAdd] = useState(false);
    const [isShowErrorLstImage, setIsShowErrorLstImage] = useState(false);
    const [lstImages, setLstImage] = useState([]);

    const addFunc = useMutation((value) =>
        Post(POST_API.ADD_POST, null, value, false)
    );

    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY, null, { PageSize: 100 })
    );
    let objectStatusFilter;
    const inforAccount = getInfomationAccountFromToken();
    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        if (userRole.includes(role.Admin) || userRole.includes(role.Manager) || userRole.includes(role.Publisher)) {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all);
        } else {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all && x.value !== postStatus.release && x.value !== postStatus.refuse);
        }
    }

    const handleCreate = async (values) => {
        const model = JSON.parse(JSON.stringify(values));
        model.publishTime = dateTimeToUTCRequest(values.publishTime);
        model.categoryId =
            values.categoryId === 'null' ? '' : values.categoryId;
        model.dataType = listCate.data.data.$values.find(
            (x) => x.id === values.categoryId
        ).dataType;
        model.status = values.postStatus.value;
        model.sourceLink = values.sourceLink;
        model.lstImages = values.listImageFromik;

        addFunc.mutate(model, {
            onSuccess: (res) => {
                values.publishTime.setHours(values.publishTime.getHours() - 7);
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastCreateError);
                } else {
                    setIsLoadingAdd(true);
                    toast.success(messageToast.messageToastCreateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });


    };

    // const listMenu = listCate.data
    //     ? listCate.data.data.$values.filter(
    //         (x) => x.dataType === dataTypeCategory.image
    //     )
    //     : [];

    const listMenu = listCate.data
        ? listCate.data.data.$values
        : [];

    // upload data image
    const uploadFile = async ({ fileUpload }) => {
        const token = getToken();
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `bearer ${token}`
            }
        };
        const data = new FormData();
        data.append('file', fileUpload);
        try {
            const res = await axios.post(FILE_MANAGER_API.UPLOAD, data, config);
            return res;
        } catch (error) {
            const errorMessage = error.response.data.message;
            return errorMessage;
        }
    };

    const handleUploadFile = async (e, listCurrent, functionSetField) => {
        if (e && e.target && e.target.files && e.target.files.length > 0) {
            const result = [];
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i].size < 1024000 * 10) {
                    const fileUpload = e.target.files[i];
                    result.push((uploadFile({ fileUpload })));
                } else {
                    toast.error("Kích thước ảnh quá lớn, hãy thử lại với ảnh nhỏ hơn 10mb");
                    continue;
                }
            }
            const responses = await Promise.all(result);
            // const lstCurentImage = JSON.parse(JSON.stringify(lstImages))
            const lstCurentImage = listCurrent;
            responses.forEach((item) => {
                const fileUpdated = item.data.$values[0];
                const modelImage = {
                    "id": uuidv4(),
                    "imagePath": fileUpdated.filePath,
                    "note": "",
                    "oder": "1"
                }
                lstCurentImage.push(modelImage);
            })
            // setIsShowErrorLstImage(false);
            // setLstImage(lstCurentImage);
            functionSetField(
                'listImageFromik',
                lstCurentImage,
                true
            );
        }
    };

    // const onClickDeleteImage = (idImage) => {
    //     if (idImage) {
    //         let lstCurentImage = JSON.parse(JSON.stringify(lstImages));
    //         lstCurentImage = lstCurentImage.filter(x => x.id !== idImage);
    //         setLstImage(lstCurentImage);
    //     }
    // }
    // const onClickAddNote = (idImage) => {
    //     if (idImage) {
    //         const valueDomInput = document.getElementById(idImage).value;
    //         const lstCurentImage = JSON.parse(JSON.stringify(lstImages));
    //         lstCurentImage.find(x => x.id === idImage).note = valueDomInput;
    //         setLstImage(lstCurentImage);
    //     }
    // }


    return (
        <Card>
            <Card.Header as="h5">Thêm mới thư viện ảnh</Card.Header>
            <Card.Body>
                {listCate.isLoading ? (
                    <div>Loading...</div>
                ) : listCate.isError ? (
                    <div>An error has occurred: ${listCate.error.message}</div>
                ) : (
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => handleCreate(values)}
                        initialValues={{
                            title: '',
                            titleEn: '',
                            description: '',
                            descriptionEn: '',
                            keywords: '',
                            ogTitle: '',
                            ogDescription: '',
                            articleTag: '',
                            content: '',
                            contentEn: '',
                            videoLink: '',
                            imagePath: '',
                            imageThumbnail: '',
                            isFocus: false,
                            isHighlight: false,
                            author: '',
                            categoryId: '',
                            postStatus: '',
                            sourceLink: '',
                            virtualViews: 0,
                            publishTime: new Date(),
                            listImageFromik: []
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Form.Control
                                                    placeholder="Tên"
                                                    type="text"
                                                    name="title"
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.title}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.title}
                                                </Form.Control.Feedback>
                                            </TabPanel>
                                            <TabPanel>
                                                <Form.Control
                                                    placeholder="Tên"
                                                    type="text"
                                                    name="titleEn"
                                                    value={values.titleEn}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.titleEn}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                        {/* <Form.Control
                                            placeholder="Tên"
                                            type="text"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Danh mục *
                                    </Form.Label>
                                    <Col sm="10">
                                        <SelectTree
                                            data={listMenu}
                                            typeChoose={dataTypeCategory.image}
                                            showSearchBox
                                            Placeholder="Danh mục"
                                            positionExpand="right"
                                            singleValueSelected={values.id}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'categoryId',
                                                    value.id
                                                );
                                            }}
                                            isInvalid={!!errors.categoryId}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.categoryId}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái bài viết *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="postStatus"
                                            id="postStatus"
                                            placeholder="Chọn trạng thái bài viết"
                                            value={values.postStatus}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'postStatus',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            // options={objectStatusPost}
                                            options={objectStatusFilter}
                                            styles={
                                                errors.postStatus !== undefined
                                                    ? styleSelectError
                                                    : styleSelectDefault
                                            }
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.postStatus}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Upload ảnh (*)
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            multiple
                                            type="file"
                                            hidden
                                            accept={acceptImage}
                                            ref={inputUpload}
                                            // onChange={(e) => handleUploadFile(e)}
                                            onChange={(e) => handleUploadFile(e, values.listImageFromik, setFieldValue)}
                                        />
                                        <button type="button" style={{}} className="w-auto ms-2" onClick={() => inputUpload.current.click()}>Chọn file tải lên</button>
                                        {
                                            values.listImageFromik && values.listImageFromik.length > 0 ?
                                                <div style={{ width: '60%', overflow: 'auto', height: "400px", display: 'flex', flexWrap: 'wrap' }}>
                                                    {values.listImageFromik.map((item, index) =>
                                                        <Card key={Number(index)} style={{ width: '30%', marginLeft: '2%' }}>
                                                            <div style={{ margin: "10%" }}>
                                                                <Image
                                                                    src={BaseGatewayImage + item.imagePath}
                                                                    height={150}
                                                                    width="100%"
                                                                    size
                                                                    rounded
                                                                />
                                                            </div>
                                                            <div style={{ margin: "5%" }}>
                                                                <Tabs>
                                                                    <TabList>
                                                                        <Tab>Tiếng việt</Tab>
                                                                        <Tab>Tiếng Anh</Tab>
                                                                    </TabList>
                                                                    <TabPanel>
                                                                        <Form.Control type="text" id={item.id} placeholder="" onChange={(e) => {
                                                                            const lstCurentImage = values.listImageFromik;
                                                                            lstCurentImage.find(x => x.id === item.id).note = e.target.value;
                                                                            setFieldValue(
                                                                                'listImageFromik',
                                                                                lstCurentImage,
                                                                                true
                                                                            );
                                                                        }} />
                                                                    </TabPanel>
                                                                    <TabPanel>
                                                                        <Form.Control type="text" id={item.id} placeholder="" onChange={(e) => {
                                                                            const lstCurentImage = values.listImageFromik;
                                                                            lstCurentImage.find(x => x.id === item.id).noteEn = e.target.value;
                                                                            setFieldValue(
                                                                                'listImageFromik',
                                                                                lstCurentImage,
                                                                                true
                                                                            );
                                                                        }} />
                                                                    </TabPanel>
                                                                </Tabs>

                                                            </div>
                                                            <div style={{ margin: "5%", display: 'flex', justifyContent: 'space-between' }}>
                                                                {/* <Button onClick={() => onClickAddNote(item.id)} variant="outline-dark" title="Thông tin bổ sung">+</Button> */}

                                                                {/* <Button onClick={() => {
                                                                    const valueDomInput = document.getElementById(item.id).value;
                                                                    const lstCurentImage = values.listImageFromik;
                                                                    lstCurentImage.find(x => x.id === item.id).note = valueDomInput;
                                                                    toast.success("Đã thêm thông tin bổ sung");
                                                                    setFieldValue(
                                                                        'listImageFromik',
                                                                        lstCurentImage,
                                                                        true
                                                                    );
                                                                }} variant="outline-dark" title="Thông tin bổ sung">+</Button> */}

                                                                <div>
                                                                    <Form.Control min="1" type="number" value={item.oder} onChange={(e) => {
                                                                        const lstCurentImage = values.listImageFromik;
                                                                        lstCurentImage.find(x => x.id === item.id).oder = e.target.value;
                                                                        setFieldValue(
                                                                            'listImageFromik',
                                                                            lstCurentImage,
                                                                            true
                                                                        );
                                                                    }} style={{ width: '60px', display: 'inline-block', marginRight: '5px' }} placeholder="" />
                                                                    {/* <Form.Control min="1" type="number" value={item.note} style={{ width: '60px', display: 'inline-block', marginRight: '5px' }} placeholder="" /> */}
                                                                    {/* <NavLink
                                                                        onClick={() => onClickDeleteImage(item.id)}
                                                                        to
                                                                        exact
                                                                        className="nav-link"
                                                                    >
                                                                        <i

                                                                            title="Xóa"
                                                                            className="nav-icon font-icon far fa-trash-alt"
                                                                        />
                                                                    </NavLink> */}
                                                                    {/* <Button onClick={() => onClickDeleteImage(item.id)} variant="danger" title="Xóa">Xóa</Button> */}

                                                                    <button onClick={() => {
                                                                        let lstCurentImage = values.listImageFromik;
                                                                        lstCurentImage = lstCurentImage.filter(x => x.id !== item.id);
                                                                        setFieldValue(
                                                                            'listImageFromik',
                                                                            lstCurentImage,
                                                                            true
                                                                        );
                                                                    }
                                                                    } type="button"
                                                                        className="btn btn-danger" variant="danger" title="Xóa">Xóa</button>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    )}
                                                </div> :
                                                null
                                        }
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                        >
                                            {errors.listImageFromik}
                                        </div>

                                    </Col>

                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh  đại diện *
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            name="imageThumbnail"
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                if (files.length > 0) {
                                                    setFieldValue(
                                                        'imagePath',
                                                        files[0].filePath,
                                                        true
                                                    );
                                                    setFieldValue(
                                                        'imageThumbnail',
                                                        files[0].thumbPath,
                                                        true
                                                    );
                                                }
                                            }}
                                        />

                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.imageThumbnail}
                                        </div>

                                        {/* <Form.Control.Feedback type="invalid">
                                        {errors.parentId}
                                    </Form.Control.Feedback> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thời gian xuất bản
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <DatePicker
                                            selected={values.publishTime}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    'publishTime',
                                                    date,
                                                    true
                                                )
                                            }
                                            }
                                            timeInputLabel="Time:"
                                            dateFormat={DATE_TIME_REACT_FORMAT}
                                            showTimeInput
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tóm tắt
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Editor
                                                    name="description"
                                                    value={values.description}
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            'description',
                                                            value
                                                        );
                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Editor
                                                    name="descriptionEn"
                                                    value={values.descriptionEn}
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            'descriptionEn',
                                                            value
                                                        );
                                                    }}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                        {/* <Editor
                                            name="description"
                                            value={values.description}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'description',
                                                    value
                                                );
                                            }}
                                        /> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nội dung
                                    </Form.Label>
                                    <Col sm="10">
                                        {/* <Editor
                                            name="content"
                                            value={values.content}
                                            onChange={(value) => {
                                                setFieldValue('content', value);
                                            }}
                                        /> */}
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Editor
                                                    name="content"
                                                    value={values.content}
                                                    onChange={async (value) => {
                                                        setFieldValue('content', value);
                                                        if (values.imagePath === "" || values.imageThumbnail === "") {
                                                            var divContent = document.createElement('div');
                                                            divContent.innerHTML = value;
                                                            var imgs = divContent.querySelectorAll('img');
                                                            if (imgs && imgs.length > 0) {
                                                                for (let i = 0; i < imgs.length; i++) {
                                                                    if (imgs[i].getAttribute("src")) {
                                                                        console.log("element.getAttribute", imgs[i].getAttribute("src"));
                                                                        await setFieldValue(
                                                                            'imagePath',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        await setFieldValue(
                                                                            'imageThumbnail',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        break;
                                                                    }
                                                                }
                                                            }

                                                        }

                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Editor
                                                    name="contentEn"
                                                    value={values.contentEn}

                                                    onChange={async (value) => {
                                                        setFieldValue('contentEn', value);
                                                        if (values.imagePath === "" || values.imageThumbnail === "") {
                                                            var divContent = document.createElement('div');
                                                            divContent.innerHTML = value;
                                                            var imgs = divContent.querySelectorAll('img');
                                                            if (imgs && imgs.length > 0) {
                                                                for (let i = 0; i < imgs.length; i++) {
                                                                    if (imgs[i].getAttribute("src")) {
                                                                        console.log("element.getAttribute", imgs[i].getAttribute("src"));
                                                                        await setFieldValue(
                                                                            'imagePath',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        await setFieldValue(
                                                                            'imageThumbnail',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        break;
                                                                    }
                                                                }
                                                            }

                                                        }

                                                    }}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tác giả
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tác giả"
                                            type="text"
                                            name="author"
                                            value={values.author}
                                            onChange={handleChange}
                                            isInvalid={!!errors.author}
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nguồn link
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Nguồn link"
                                            type="text"
                                            name="sourceLink"
                                            value={values.sourceLink}
                                            onChange={handleChange}
                                            isInvalid={!!errors.sourceLink}
                                        />
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Là tiêu điểm
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary1"
                                            type="checkbox"
                                            placeholder="Là tiêu điểm"
                                            name="isFocus"
                                            checked={values.isFocus}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary1">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nổi bật
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary2"
                                            type="checkbox"
                                            placeholder="Nổi bật"
                                            name="isHighlight"
                                            checked={values.isHighlight}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary2">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Lượt view ảo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="virtualViews"
                                            placeholder=""
                                            value={values.virtualViews}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Từ khóa Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Từ khóa"
                                            type="text"
                                            name="keywords"
                                            value={values.keywords}
                                            onChange={handleChange}
                                            isInvalid={!!errors.keywords}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.keywords}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tiêu đề Og"
                                            type="text"
                                            name="ogTitle"
                                            value={values.ogTitle}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogTitle}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogTitle}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Mô tả Og"
                                            type="text"
                                            name="ogDescription"
                                            value={values.ogDescription}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogDescription}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogDescription}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tag Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tag bài báo"
                                            type="text"
                                            name="articleTag"
                                            value={values.articleTag}
                                            onChange={handleChange}
                                            isInvalid={!!errors.articleTag}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.articleTag}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái bài viết
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="postStatus"
                                            id="postStatus"
                                            placeholder="Chọn trạng thái bài viết"
                                            value={values.postStatus}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'postStatus',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            options={objectStatusPost}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.postStatus}
                                        </div>
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                                disabled={isLoadingAdd}
                                            >
                                                Thêm
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
                <ReactSpinner loadings={isLoadingAdd} />
            </Card.Body>
        </Card>
    );
};

export default LibraryImageAdd;
