export const AUTH = {
    NAME_SLICE: 'auth',
    LOGIN_THUNK: 'auth/loginUser',
    CHANGE_PASSWORD: 'auth/changePassword'
};
export const ACCOUNT = {
    NAME_SLICE: 'account',
    GET_PAGING: 'account/get-paging',
    ADD_ACCOUNT: 'account/add-account',
    UPDATE_ACCOUNT_CONST: 'account/update-account',
    DELETE_ACCOUNT_CONST: 'account/delete-account',
    GET_BY_ID_ACCOUNT: 'account/get-item-by-id',
    DETAIL_ACCOUNT: 'account/detail',
    CREATE_DETAIL_ACCOUNT: 'account/create-detail',
    CREATE_PROJECT_ACCOUNT: 'account/create-project',
    UPDATE_PROJECT_ACCOUNT: 'account/update-project',
    DELETE_PROJECT_ACCOUNT: 'account/delete-project',
    GET_PAGING_PROJECT: 'account/get-paging-project',
    GET_BY_ID_PROJECT: 'account/get-item-by-id-project'
};

export const NEWSCRAWLER = {
    NAME_SLICE: 'newscrawler',
    GET_PAGING: 'newscrawler/get-paging',
    GET_ITEM: 'newscrawler/get-item'
};

export const SLIDE_IMAGE = {
    NAME_SLICE: 'slideImage',
    GET_PAGING: 'slideImage/get-paging',
    GET_ITEM: 'slideImage/get-item'
};
export const REPORT_POST_CONST = {
    NAME_SLICE: 'report-post',
    NAME_SLICE_SYNTHENTIC: 'report-post-synthentic',
    GET_PAGING: 'report-post/get-paging'
};
export const REPORT_ADVERTISING_ANALYSIS_CONST = {
    NAME_SLICE: 'report-advertising',
    GET_COUNT_ADVERTISING_ANALYSIS: 'report-advertising/count'
};
export const BANNER_CONST = {
    NAME_SLICE: 'banner',
    GET_PAGING: 'banner/get-paging',
    ADD: 'banner/add',
    UPDATE: 'banner/update',
    DELETE: 'banner/delete',
    GET_BY_ID: 'banner/get-item-by-id'
};
export const EMAILCONFIG = {
    NAME_SLICE: 'emailconfig',
    GET_PAGING: 'emailconfig/get-paging',
    GET_ITEM: 'emailconfig/get-item',
    ADD_EMAILCONFIG: 'emailconfig/add-emailconfig',
    EDIT_EMAILCONFIG: 'emailconfig/edit-emailconfig',
    DELETE_EMAILCONFIG: 'emailconfig/delete-emailconfig'
};

export const PARTNER = {
    NAME_SLICE: 'partner',
    GET_PAGING: 'partner/get-paging',
    GET_ITEM: 'partner/get-item',
    ADD_PARTNER: 'partner/add-partner',
    EDIT_PARTNER: 'partner/edit-partner',
    DELETE_PARTNER: 'partner/delete-partner'
};

export const POST = {
    NAME_SLICE: 'post',
    GET_PAGING: 'post/get-paging',
    GET_PAGING_REPORT_POST_CREATEBY: 'post/get-paing-report-createby',
    EXPORT_EXCEL_REPORT_POST_CREATEBY: 'post/export-excel-report-createby',
};

export const CATEGORY = {
    NAME_SLICE: 'category',
    GET_PAGING: 'category/get-paging',
    GET_ITEM: 'category/get-item',
    ADD_CATEGORY: 'category/add-category',
    EDIT_CATEGORY: 'category/edit-category',
    DELETE_CATEGORY: 'category/delete-category'
};
export const LOGIN_HISTORY_CONST = {
    NAME_SLICE: 'loginHistory',
    GET_PAGING: 'loginHistory/get-paging',
    GET_STATISTIC: 'loginHistory/get-statistic'
};
