import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@components';
import PropTypes from 'prop-types';
import { Form, Row, ProgressBar } from 'react-bootstrap';
import { BaseGatewayImage, FILE_MANAGER_API } from '@app/utils/apiUrl';
import axios from 'axios';
import { getToken } from '@app/utils/helper';
import { toast } from 'react-toastify';
import { acceptDoc } from '@app/utils/define';

const DocumentInput = (props) => {
    const { value, enableReinitialize, isInvalid, errorsValue, onChangeProp } = props;
    const inputUpload = useRef();
    const [completed, setCompleted] = useState(0);
    const [docUrl, setDocUrl] = useState('');

    useEffect(() => {
        if (
            (value && value.length > 0 && docUrl.length === 0) ||
            enableReinitialize
        ) {
            setDocUrl(value);
        }
    }, [value]);


    // hàm để upload file video lên server
    const uploadFile = async ({ fileUpload, setCompleted }) => {
        const token = getToken();
        const config = {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(percentCompleted);
                setCompleted(percentCompleted);
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `bearer ${token}`
            }
        };
        const data = new FormData();
        data.append('file', fileUpload);
        try {
            const res = await axios.post(FILE_MANAGER_API.UPLOAD, data, config);
            return res;
        } catch (error) {
            const errorMessage = error.response.data.message;
            console.log(errorMessage);
            return errorMessage;
        }
    };


    const handleUploadFile = async (event) => {
        const fileUpload = event.target.files[0];
        const fileSize = fileUpload.size / 1024 / 1024;
        if (fileSize > 20) {
            toast.error("Kích thước file quá lớn, hãy thử lại với tệp nhỏ hơn 20MB");
        } else {
            const result = await uploadFile({ fileUpload, setCompleted });
            const fileUpdated = result.data.$values[0];
            setDocUrl(fileUpdated.filePath);
            onChangeProp(fileUpdated.filePath);
            setCompleted(0);
            toast.success("Tải file thành công!");
        }
    };

    const isUploaded = docUrl && completed === 0;

    function checkURLImg(url) {
        return (url.match(/\.(jpeg|jpg|gif|png|jpe)$/) != null);
    }
    function checkURLDocument(url) {
        return (url.match(/\.(doc|docx|txt|xls|xlsx|ppt|pptx|pdf)$/) != null);
    }
    function dowLoadFile(url) {
        try {
            if (url) {
                var a = document.createElement("a");
                a.href = url;
                let arrayUrrl = url.split("/");
                let fileName = arrayUrrl[arrayUrrl.length - 1];
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }
        } catch (error) {

        }
    }

    return (
        <>
            {
                checkURLImg(BaseGatewayImage + docUrl) ?
                    <img style={{ width: '200px', height: '200px' }} src={BaseGatewayImage + docUrl}>
                    </img> : <></>
            }
            <Row style={{ marginTop: '15px' }} className="col">
                <Button className="col-sm-2 form-control-btn" onClick={() => inputUpload.current.click()}>Upload file</Button>
                <Form.Control
                    className="col-sm-8 form-control-teptin"
                    placeholder="Đường dẫn tệp tin"
                    type="text"
                    name="documentLink"
                    value={docUrl}
                    onChange={(e) => {
                        setDocUrl(e.target.value);
                        onChangeProp(e.target.value);
                    }}
                    isInvalid={isInvalid}
                />

                {/* <div onClick={() => dowLoadFile(BaseGatewayImage + docUrl)} className="col-sm-2">
                    <i style={{ fontSize: '25px', cursor: 'pointer' }} className="fas fa-download dowload" />
                </div > */}

                <div className="col-sm-2">
                    <a href={BaseGatewayImage + docUrl} target='_blank'>
                        <i style={{ fontSize: '25px', cursor: 'pointer' }} className="fas fa-link" />
                    </a>
                </div>

                <Form.Control
                    type="file"
                    hidden
                    accept={acceptDoc}
                    ref={inputUpload}
                    onChange={(e) => handleUploadFile(e)}
                />
                <Form.Control.Feedback type="invalid">
                    {errorsValue}
                </Form.Control.Feedback>
            </Row>
            <Row className="col">
                <ProgressBar
                    className="progress-striped active w-100"
                    style={{
                        display: isUploaded ? 'none' : 'flex'
                    }}
                    variant="success"
                    animated
                    now={completed}
                />
            </Row>
        </>
    );
};

DocumentInput.propTypes = {
    value: PropTypes.string,
    enableReinitialize: PropTypes.bool,
    onChangeProp: PropTypes.func
};

DocumentInput.defaultProps = {
    enableReinitialize: false,
    onChangeProp: () => { },
    value: ''
};

export default DocumentInput;
