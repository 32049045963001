import React, { useState } from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { FaFolderOpen, FaFolder } from 'react-icons/fa';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import { Post, Get } from '@app/utils/httpProvider';
import { GIAODIEN_API, CATEGORY_API } from '@app/utils/apiUrl';

import {
    messageError,
    messageToast,
    status200,
    acceptImage,
    dataTypeCategory
} from '../../utils/define';
import ReactSpinner from '../../components/reactSpinner/ReactSpinner';
import SelectionMutil from '../../components/selectionMutil/SelectionMutil';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    key: yup.string().required(messageError.emptyMessage),
});

const GiaodienAdd = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [categoryValues, setCategoryValues] = useState([]);
    const [forceValidate, setForceValidate] = useState(false);
    // danh sách các object dataType đã chọn
    const [dataTypeSelects, setDataTypeSelects] = useState([])
    const addFunc = useMutation((value) =>
        Post(GIAODIEN_API.ADD_GIAODIEN, null, value, false)
    );

    const handleCreate = async (values) => {
        const model = values;
        model.cateIds = JSON.stringify(dataTypeSelects.map((x) => x.id));
        addFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastCreateError);
                } else {
                    setIsLoading(true);
                    toast.success(messageToast.messageToastCreateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };


    // lấy danh sách các danh mục
    const dataCategory = useQuery(
        ['categoryData'],
        () =>
            Get(CATEGORY_API.GET_ALL_CATEGORY, null)
    );

    let lstCategoryMap = [];
    if (dataCategory && dataCategory.data && dataCategory.data.data) {
        const lstCateInformation = dataCategory.data.data.$values.filter(
            (x) => x.dataType === dataTypeCategory.information || dataTypeCategory.introduce || dataTypeCategory.image || dataTypeCategory.video
        )
        if (lstCateInformation) {
            lstCateInformation.forEach(item => {
                lstCategoryMap.push({
                    id: item.id,
                    parentId: item.parentId,
                    name: item.name,
                    type: item.dataType
                });
            });
        }
    }

    const handleSelectCategory = data => {
        setDataTypeSelects(data);
        if (data) {
            setCategoryValues(data.map(x => x.id));
        }
    };

    return (
        <Card>
            <Card.Header as="h5">Thêm thành phần</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => handleCreate(values)}
                    initialValues={{
                        name: '',
                        key: '',
                        cateIds: [],
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tên *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tên"
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Key *
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Key"
                                        type="text"
                                        name="key"
                                        value={values.key}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className="col-sm-2 col-form-label">
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="ml-3"
                                    >
                                        Danh mục :
                                    </label>
                                </Form.Label>
                                <Col sm="10">
                                    <SelectionMutil
                                        data={lstCategoryMap}
                                        showCheckboxOnTree={true}
                                        EnableValidate={true}
                                        ErrorMessage={""}
                                        multiSelect={true}
                                        Placeholder="Danh mục"
                                        IconExpand={FaFolder}
                                        IconCollapse={FaFolderOpen}
                                        positionExpand="right"
                                        multiValueSelected={categoryValues}
                                        onChange={handleSelectCategory}
                                        ForceValidate={forceValidate}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Col md={{ span: 10, offset: 2 }}>
                                    <ButtonGroup className="mb-2">
                                        <Button
                                            type="submit"
                                            theme="primary"
                                            disabled={isLoading}
                                        >
                                            Thêm
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            onClick={() =>
                                                history.goBack()
                                            }
                                            theme="secondary"
                                        >
                                            Hủy
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
                <ReactSpinner loadings={isLoading} />
            </Card.Body>
        </Card>
    );
};

export default GiaodienAdd;
