import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useMutation} from 'react-query';
import {useTranslation} from 'react-i18next';
import {Card, Form, Row, Col} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {URI_INTERNAL} from '../../utils/pathLocations';
import {
    messageError,
    status200,
    status400,
    messageToast,
    typeError
} from '../../utils/define';
import {Post} from '../../utils/httpProvider';
import {AUTH_API} from '../../utils/apiUrl';

const ForgotPassword = () => {
    const [t] = useTranslation();
    const history = useHistory();

    const addMutation = useMutation((value) =>
        Post(AUTH_API.FORGOT_PASSWORD, null, value)
    );

    const handlePost = async (values, actions) => {
        addMutation.mutate(values, {
            onSuccess: async (respone) => {
                try {
                    if (respone.status === status200) {
                        history.push(URI_INTERNAL.RECOVER_PASSWORD_PATH);
                    }
                    if (respone.status === status400) {
                        const result = await respone.json();
                        if (result.type) {
                            if (result.type === typeError.Email) {
                                actions.setErrors({
                                    email: result.errors
                                        ? result.errors.content[0]
                                        : ''
                                });
                            }
                            if (result.type === typeError.Code) {
                                toast.error(result.errors.content[0]);
                                history.push(
                                    URI_INTERNAL.RECOVER_PASSWORD_PATH
                                );
                            }
                        }
                    }
                } catch (error) {
                    toast.error(messageToast.messageToastTryCatch);
                }
            },
            onError: () => {}
        });
    };

    document.getElementById('root').classList = 'hold-transition login-page';

    const schema = yup.object().shape({
        email: yup
            .string()
            .email(messageError.emailMessage)
            .required(messageError.emptyMessage)
    });
    return (
        <div className="login-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <Link to="/" className="h1">
                        <b>CMS Tieng hat ha noi 2024</b>
                        {/* <span>LTE</span> */}
                    </Link>
                </div>
                <div className="card-body">
                    <p className="login-box-msg">
                        {/* {t('recover.forgotYourPassword')} */}
                        Bạn quên mật khẩu? Chúng tôi sẽ giúp bạn lấy lại mật
                        khẩu
                    </p>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values, actions) =>
                            handlePost(values, actions)
                        }
                        initialValues={{
                            email: ''
                        }}
                    >
                        {({handleSubmit, handleChange, values, errors}) => (
                            <form onSubmit={handleSubmit}>
                                <div className="input-group mb-3">
                                    {/* <input
                                        // ref={setEmailInputRef}
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={
                                            !!errors.email}
                                    /> */}
                                    <Form.Control
                                        placeholder="email"
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'block',
                                        color: '#dc3545'
                                    }}
                                >
                                    {errors.email}
                                </div>
                                {/* <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback> */}
                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-block"
                                        >
                                            {t('recover.requestNewPassword')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                    <p className="mt-3 mb-1">
                        <Link to={URI_INTERNAL.LOGIN_PATH}>
                            {/* {t('login.button.signIn.label')} */}
                            Đăng nhập
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
