import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {changeValueSearch} from '@store/reducers/search';
import Messages from './messages-dropdown/MessagesDropdown';
import Notifications from './notifications-dropdown/NotificationsDropdown';
import Languages from './languages-dropdown/LanguagesDropdown';
import User from './user-dropdown/UserDropdown';
import { BaseGatewayClient } from "../../../utils/apiUrl";

const Header = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const [search, setSearch] = useState('');

    React.useEffect(() => {
        const paramUrl = new URLSearchParams(location.search.substring(1));
        if (
            paramUrl.get('search') != null &&
            paramUrl.get('search') !== search
        ) {
            setSearch(paramUrl.get('search'));
        }
    }, []);

    const submitSearch = async () => {
        await dispatch(changeValueSearch(search));
    };
    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <button
                        type="button"
                        className="nav-link"
                        data-widget="pushmenu"
                        href="#"
                    >
                        <i className="fas fa-bars" />
                    </button>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/" className="nav-link">
                        Dashboard
                    </Link>
                </li>
                <li style={{margin:'auto'}} className="nav-item d-none d-sm-inline-block">
                        <a target="blank" href={BaseGatewayClient}>Trang chủ</a>
                </li>
                {/* <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/" className="nav-link">
                        {t('header.label.contact')}
                    </Link>
                </li> */}
            </ul>
            <form className="form-inline ml-3">
                <div className="input-group input-group-sm">
                    <input
                        // defaultValue={search}
                        defaultValue={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="form-control form-control-navbar"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                    />
                    <div className="input-group-append">
                        <button
                            onClick={() => submitSearch()}
                            className="btn btn-navbar"
                            type="button"
                        >
                            <i className="fas fa-search" />
                        </button>
                    </div>
                </div>
            </form>
            <ul className="navbar-nav ml-auto">
                {/* <Messages />
                <Notifications />
                <Languages /> */}
                <User />
                {/* <li className="nav-item">
          <button
            className="nav-link"
            data-widget="control-sidebar"
            data-slide="true"
            type="button"
          >
            <i className="fas fa-th-large" />
          </button>
        </li> */}
            </ul>
        </nav>
    );
};

export default Header;
