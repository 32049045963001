import React from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import { Get, Put } from '@app/utils/httpProvider';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import Editor from '@app/components/editor/Editor';
import Select from 'react-select';
import { NIL as NIL_UUID } from 'uuid';
import {
    messageError,
    messageToast,
    acceptImage,
    objectDataTypeCategory,
    styleSelectDefault,
    styleSelectError,
    status200,
    dataTypeCategory,
    objectDataTypeVideo,
} from '../../utils/define';
import { BaseGatewayImage, CATEGORY_API } from '../../utils/apiUrl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    dataType: yup.object().required(messageError.emptyMessage)
});

const CategoryEdit = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const cateId = paramUrl.get('id');

    const cateItem = useQuery([cateId], () =>
        Get(CATEGORY_API.GET_ITEM_CATEGORY, cateId)
    );

    // const listCate = useQuery(['getAllCate'], () =>
    //     Get(CATEGORY_API.GET_ALL_CATEGORY)
    // );

    const editFunc = useMutation((value) =>
        Put(CATEGORY_API.UPDATE_CATEGORY, value.id, value.data)
    );

    const handleEdit = async (values) => {
        const model = { ...values };
        // model.parentId = cateItem.data.parentId;
        // model.parentId = values.parentId === 'null' ? '' : values.parentId;
        model.dataType = values.dataType.value;
        model.typeVideo = values.typeVideo.value;
        const modelUpdate = {
            data: model,
            id: cateId
        };

        editFunc.mutate(modelUpdate, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    toast.success(messageToast.messageToastUpdateSuccess);
                    queryClient.setQueryData(cateId, modelUpdate);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    // const isLoading = cateItem.isLoading || listCate.isLoading;
    // const isError = cateItem.isError || listCate.isError;
    // const error = cateItem.isError ? cateItem.error : listCate.error;

    const isLoading = cateItem.isLoading ? true : false;
    const isError = cateItem.isError ? true : false;
    const error = cateItem.isError ? cateItem.error : null;

    // let menuData = [
    //     {
    //         name: 'Danh mục gốc',
    //         id: 'null',
    //         parentId: NIL_UUID
    //     }
    // ];
    // if (!listCate.isLoading && !listCate.isError) {
    //     menuData = menuData
    //         .concat(listCate.data.data.$values)
    //         .filter((x) => x.id !== cateId);
    // }
    console.log("cateItem.data", cateItem.data);
    return (
        <Card>
            <Card.Header as="h5">Sửa thông tin danh mục</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : cateItem.data == null ? (
                    <div>Danh mục không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            name:
                                cateItem.data && cateItem.data.name
                                    ? cateItem.data.name
                                    : '',
                            nameEn:
                                cateItem.data && cateItem.data.nameEn
                                    ? cateItem.data.nameEn
                                    : '',
                            parentId:
                                cateItem.data.parentId === NIL_UUID
                                    ? 'null'
                                    : cateItem.data.parentId,
                            description:
                                cateItem.data && cateItem.data.description
                                    ? cateItem.data.description
                                    : '',
                            descriptionEn:
                                cateItem.data && cateItem.data.descriptionEn
                                    ? cateItem.data.descriptionEn
                                    : '',
                            content:
                                cateItem.data && cateItem.data.content
                                    ? cateItem.data.content
                                    : '',
                            contentEn:
                                cateItem.data && cateItem.data.contentEn
                                    ? cateItem.data.contentEn
                                    : '',
                            keywords:
                                cateItem.data && cateItem.data.keywords
                                    ? cateItem.data.keywords
                                    : '',
                            ogTitle:
                                cateItem.data && cateItem.data.ogTitle
                                    ? cateItem.data.ogTitle
                                    : '',
                            ogDescription:
                                cateItem.data && cateItem.data.ogDescription
                                    ? cateItem.data.ogDescription
                                    : '',
                            orderNumber:
                                cateItem.data && cateItem.data.orderNumber
                                    ? cateItem.data.orderNumber
                                    : 0,
                            dataType: cateItem.data
                                ? objectDataTypeCategory.find(
                                    (x) => x.value === cateItem.data.dataType
                                )
                                : '',
                            typeVideo: cateItem.data
                                ? objectDataTypeVideo.find(
                                    (x) => x.value === cateItem.data.typeVideo
                                )
                                : objectDataTypeVideo[0],
                            imagePath:
                                cateItem.data && cateItem.data.imagePath
                                    ? cateItem.data.imagePath
                                    : '',
                            imageThumbnail:
                                cateItem.data && cateItem.data.imageThumbnail
                                    ? cateItem.data.imageThumbnail
                                    : '',
                            isMenu:
                                cateItem.data && cateItem.data.isMenu
                                    ? cateItem.data.isMenu
                                    : false
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tên *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Form.Control
                                                    placeholder="Tên"
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </TabPanel>
                                            <TabPanel>
                                                <Form.Control
                                                    placeholder="Tên"
                                                    type="text"
                                                    name="nameEn"
                                                    value={values.nameEn}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.nameEn}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.nameEn}
                                                </Form.Control.Feedback>
                                            </TabPanel>
                                        </Tabs>
                                        {/* <Form.Control
                                            placeholder="Tên"
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tóm tắt
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Editor
                                                    name="description"
                                                    value={values.description}
                                                    onChange={(value) => {
                                                        setFieldValue('description', value);
                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Editor
                                                    name="descriptionEn"
                                                    value={values.descriptionEn}
                                                    onChange={(value) => {
                                                        setFieldValue('descriptionEn', value);
                                                    }}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                        {/* <Editor
                                            name="description"
                                            value={values.description}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'description',
                                                    value
                                                );
                                            }}
                                        /> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            values={
                                                values.imageThumbnail
                                                    ? [
                                                        BaseGatewayImage +
                                                        values.imageThumbnail
                                                    ]
                                                    : []
                                            }
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                setFieldValue(
                                                    'imageThumbnail',
                                                    files.length > 0
                                                        ? files[0].thumbPath
                                                        : '',
                                                    true
                                                );
                                                setFieldValue(
                                                    'imagePath',
                                                    files.length > 0
                                                        ? files[0].filePath
                                                        : '',
                                                    true
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nội dung
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Editor
                                                    name="content"
                                                    value={values.content}
                                                    onChange={(value) => {
                                                        setFieldValue('content', value);
                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Editor
                                                    name="contentEn"
                                                    value={values.contentEn}
                                                    onChange={(value) => {
                                                        setFieldValue('contentEn', value);
                                                    }}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                        {/* <Editor
                                            name="content"
                                            value={values.content}
                                            onChange={(value) => {
                                                setFieldValue('content', value);
                                            }}
                                        /> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Là Menu
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary1"
                                            type="checkbox"
                                            placeholder="Là Menu"
                                            name="isMenu"
                                            checked={values.isMenu}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary1">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Kiểu dữ liệu
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="dataType"
                                            id="dataType"
                                            placeholder="Chọn kiểu dữ liệu"
                                            value={values.dataType}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'dataType',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            options={objectDataTypeCategory}
                                            styles={
                                                errors.dataType !== undefined
                                                    ? styleSelectError
                                                    : styleSelectDefault
                                            }
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.dataType}
                                        </div>
                                    </Col>
                                </Form.Group>
                                {
                                    values.dataType && values.dataType.value === dataTypeCategory.video ?
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Loại video
                                            </Form.Label>
                                            <Col sm="10">
                                                <Select
                                                    name="typeVideo"
                                                    id="typeVideo"
                                                    placeholder="Chọn loại video"
                                                    value={values.typeVideo}
                                                    onChange={(selectedOption) => {
                                                        const event = {
                                                            target: {
                                                                name: 'typeVideo',
                                                                value: selectedOption
                                                            }
                                                        };
                                                        handleChange(event);
                                                    }}
                                                    options={objectDataTypeVideo}
                                                />
                                                {/* <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.dataType}
                                    </div> */}
                                            </Col>
                                        </Form.Group>
                                        : <></>
                                }
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Sắp xếp
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="orderNumber"
                                            placeholder="Sắp xếp"
                                            value={values.orderNumber}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Từ khóa Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Từ khóa"
                                            type="text"
                                            name="keywords"
                                            value={values.keywords}
                                            onChange={handleChange}
                                            isInvalid={!!errors.keywords}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.keywords}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tiêu đề Og"
                                            type="text"
                                            name="ogTitle"
                                            value={values.ogTitle}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogTitle}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogTitle}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Mô tả Og"
                                            type="text"
                                            name="ogDescription"
                                            value={values.ogDescription}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogDescription}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogDescription}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Sửa
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card.Body>
        </Card>
    );
};

export default CategoryEdit;
