import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import SortableTree, {
    getVisibleNodeCount
} from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import { CATEGORY_API } from '@app/utils/apiUrl';
import { DeleteSingle, Get, Put } from '@app/utils/httpProvider';
import NodeRendererDefault from '@app/components/SortableTree/NodeRendererDefault';
import { NIL as NIL_UUID } from 'uuid';
import { toast } from 'react-toastify';
import { createUrlWithParam } from '../../utils/helper';
import { URI_INTERNAL } from '../../utils/pathLocations';
import { ConfirmModal } from '../../modules/modal/ConfirmModal';
import { messageToast, status200 } from '../../utils/define';

const Category = () => {
    const queryClient = useQueryClient();

    const history = useHistory();
    const location = useLocation();
    const valueSearch = useSelector((state) => state.search.valueSearch);

    const [search, setSearch] = useState('');
    const [treeData, setTreeData] = useState([]);
    const [isTreeChange, setIsTreeChange] = useState(false);

    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [idWantDelete, setIdWantDelete] = useState('');
    const [confirmDelMessage, setConfirmDelMessage] = useState('');

    const paramUrl = new URLSearchParams(location.search.substring(1));
    if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
        setSearch(paramUrl.get('search'));
    }

    const { isLoading, isError, error, data, refetch } = useQuery(
        ['cateData', valueSearch],
        () =>
            Get(CATEGORY_API.GET_PAGING_CATEGORY, null, {
                Keyword: valueSearch ? valueSearch : '',
                PageSize:100
            })
    );

    const updateStatusIsMenu = useMutation((value) =>
        Put(CATEGORY_API.UPDATE_CATEGORY, value.id, value.data)
    );

    const updateTree = useMutation((dataUpdate) =>
        Put(CATEGORY_API.UPDATE_TREE, null, dataUpdate)
    );

    const deleteFunc = useMutation((value) =>
        DeleteSingle(CATEGORY_API.DELETE_CATEGORY, value, null)
    );

    const replaceHistory = async (Search) => {
        history.replace(
            createUrlWithParam(URI_INTERNAL.CATEGORY_PATH, {
                search: Search
            })
        );
    };

    React.useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            replaceHistory(valueSearch);
        }
    }, [valueSearch]);

    const deleteAction = (id, name) => {
        setIdWantDelete(id);
        setConfirmDelMessage(name);
        setOpenConfirmDeleteModal(true);
    };

    const acceptDelete = () => {
        if (idWantDelete !== '') {
            // đồng ý xóa dữ liệu
            // deleteMenuAction(idWantDelete, dispatch);
            deleteFunc.mutate(idWantDelete, {
                onSuccess: (res) => {
                    if (res.status === 201 || res.status === 200) {
                        refetch();
                        toast.success(messageToast.messageToastDeleteSuccess);
                    } else {
                        toast.error(messageToast.messageToastDeleteError);
                    }
                },
                onError: (res) => {
                    toast.error(messageToast.messageToastTryCatch);
                }
            });

            replaceHistory(search);
            setOpenConfirmDeleteModal(false);
        }
    };

    const cancelDelete = () => {
        setIdWantDelete('');
        setOpenConfirmDeleteModal(false);
    };

    const genTree = (items, id = NIL_UUID, levelTree = 1, link = 'parentId') =>
        items
            .filter((item) => item[link] === id)
            .map((item) => ({
                ...item,
                title: item.name,
                level: levelTree,
                children: genTree(items, item.id, levelTree + 1)
            }));

    const setIsMenu = (item) => {
        item.parentId = item.parentId === NIL_UUID ? '' : item.parentId;
        item.isMenu = !item.isMenu;
        const newData = data.data.$values.map((x) => {
            if (x.id === item.id) {
                x.isMenu = item.isMenu;
            }
            return x;
        });

        const modelUpdate = {
            data: item,
            id: item.id
        };

        updateStatusIsMenu.mutate(modelUpdate, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    let listCateTree = [];
                    listCateTree = genTree(newData);
                    setTreeData(listCateTree);
                    toast.success(messageToast.messageToastUpdateSuccess);
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    const handleUpdateTree = () => {
        updateTree.mutate(treeData, {
            onSuccess: (res) => {
                if (res.status !== 200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    setIsTreeChange(false);
                    toast.success(messageToast.messageToastUpdateSuccess);
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    useEffect(() => {
        if (
            !isLoading &&
            !isError &&
            data.data &&
            data.data.$values.length > 0
        ) {
            let listCateTree = [];
            listCateTree = genTree(data.data.$values);
            setTreeData(listCateTree);
        }
    }, [data]);

    const openCkfinder = () => {
        window.CKFinder.modal({
            height: 600
        });
    };

    return (
        <div className="container-fluid">
            <Card>
                <Card.Header>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5>Danh sách danh mục</h5>
                        <div className="d-flex">
                            {/* <button
                                type="button"
                                className="btn btn-info"
                                onClick={() => openCkfinder()}
                            >
                                Ckfinder
                            </button> */}
                            <NavLink
                                className="nav-link-reject nav-link"
                                to={URI_INTERNAL.CATEGORY_PATH}
                            >
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    disabled={!isTreeChange}
                                    onClick={() => handleUpdateTree()}
                                >
                                    Lưu kéo thả
                                </button>
                            </NavLink>
                            <NavLink
                                className="nav-link-reject nav-link"
                                to={URI_INTERNAL.CATEGORY_CREATE_PATH}
                            >
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    Thêm mới
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : isError ? (
                        <div>An error has occurred: ${error.message}</div>
                    ) : (
                        <>
                            <div className="dt-bootstrap4">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6" />
                                    <div className="col-sm-12 col-md-6" />
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div
                                            style={{
                                                height:
                                                    getVisibleNodeCount({
                                                        treeData: treeData
                                                    }) * 70
                                            }}
                                        >
                                            <SortableTree
                                                treeData={treeData}
                                                onChange={(tree) => {
                                                    setTreeData(tree);
                                                }}
                                                onMoveNode={({ node, nextParentNode }) => {
                                                    const newData =
                                                        data.data.$values.map((x) => {
                                                            if (x.id === node.id) {
                                                                x.parentId = nextParentNode === null ? NIL_UUID : nextParentNode.id;
                                                            }
                                                            return x;
                                                        });
                                                    queryClient.setQueryData('cateData', newData);
                                                    setIsTreeChange(true);
                                                }}
                                                nodeContentRenderer={
                                                    NodeRendererDefault
                                                }
                                                generateNodeProps={(row) => {
                                                    return {
                                                        title: row.node.title,
                                                        subtitle: (
                                                            <div
                                                                style={{
                                                                    lineHeight:
                                                                        '2em'
                                                                }}
                                                            >
                                                                {
                                                                    row.node
                                                                        .subtitle
                                                                }
                                                            </div>
                                                        ),
                                                        // icons: (
                                                        //     <i
                                                        //         className="fas fa-pen"
                                                        //         style={{
                                                        //             fontSize: 18
                                                        //         }}
                                                        //     />
                                                        // ),
                                                        buttons: [
                                                            <div
                                                                className={`pl-4 pr-4 pt-2 pb-2 ${row.node
                                                                    .isMenu
                                                                    ? 'text-success'
                                                                    : 'text-danger'
                                                                    } `}
                                                                title={`${row.node
                                                                    .isMenu
                                                                    ? 'Là menu'
                                                                    : 'Không phải menu'
                                                                    }`}
                                                                role="presentation"
                                                                onClick={() =>
                                                                    setIsMenu(
                                                                        row.node
                                                                    )
                                                                }
                                                            >
                                                                <i className="fas fa-circle" />
                                                            </div>,
                                                            <NavLink
                                                                className="nav-link-reject nav-link text-info"
                                                                to={createUrlWithParam(
                                                                    URI_INTERNAL.CATEGORY_EDIT_PATH,
                                                                    {
                                                                        id: row
                                                                            .node
                                                                            .id
                                                                    }
                                                                )}
                                                            >
                                                                <i className="fas fa-pen" />
                                                            </NavLink>,
                                                            <NavLink
                                                                onClick={() =>
                                                                    deleteAction(
                                                                        row.node
                                                                            .id,
                                                                        row.node
                                                                            .title
                                                                    )
                                                                }
                                                                to
                                                                exact
                                                                className="nav-link text-danger"
                                                            >
                                                                <i
                                                                    title="Xóa"
                                                                    className="nav-icon font-icon far fa-trash-alt"
                                                                />
                                                            </NavLink>
                                                        ]
                                                    };
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ConfirmModal
                                isOpen={openConfirmDeleteModal}
                                confirmType="delete"
                                handleAccept={() => acceptDelete()}
                                handleCancel={() => cancelDelete()}
                                content={confirmDelMessage}
                            />
                        </>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

export default Category;
