import React, { useState } from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import { Get, Put } from '@app/utils/httpProvider';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import DatePicker from 'react-datepicker';
import Editor from '@app/components/editor/Editor';
import Select from 'react-select';
import SelectTree from '@app/components/select-tree/SelectTree';
import { NIL as NIL_UUID } from 'uuid';
import ReactPlayer from 'react-player';
import { dateTimeToUTCRequest, getInfomationAccountFromToken } from '@app/utils/helper';
import {
    messageError,
    messageToast,
    acceptImage,
    objectStatusPost,
    styleSelectDefault,
    styleSelectError,
    dataTypeCategory,
    DATE_TIME_REACT_FORMAT,
    role,
    postStatus
} from '@app/utils/define';
import { BaseGatewayImage, POST_API, CATEGORY_API } from '@app/utils/apiUrl';
import ReactSpinner from '@app/components/reactSpinner/ReactSpinner';
import VideoInput from '@app/components/video-input/VideoInput';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const schema = yup.object().shape({
    title: yup.string().required(messageError.emptyMessage),
    // imageThumbnail: yup.string().required(messageError.emptyMessage),
    postStatus: yup.object().required(messageError.emptyMessage),
    videoLink: yup.string().required(messageError.emptyMessage),
    categoryId: yup.string().required(messageError.emptyMessage),
});

const VideoEdit = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const location = useLocation();
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const videoId = paramUrl.get('id');

    const postItem = useQuery([videoId], () =>
        Get(POST_API.GET_ITEM_POST, videoId)
    );

    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY, null, { PageSize: 100 })
    );

    const addMutation = useMutation((value) =>
        Put(POST_API.UPDATE_POST, value.id, value.data)
    );

    let objectStatusFilter;
    const inforAccount = getInfomationAccountFromToken();
    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        if (userRole.includes(role.Admin) || userRole.includes(role.Manager) || userRole.includes(role.Publisher)) {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all);
        } else {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all && x.value !== postStatus.release);
        }
    }


    const handleEdit = async (values) => {
        const model = { ...values };
        model.categoryId =
            values.categoryId === 'null' ? '' : values.categoryId;
        model.imagePath = values.imagePath;

        model.imageThumbnail = values.imageThumbnail;
        model.publishTime = dateTimeToUTCRequest(values.publishTime);

        model.status = values.postStatus.value;

        model.dataType = listCate.data.data.$values.find(
            (x) => x.id === values.categoryId
        ).dataType;

        const modelUpdate = {
            data: model,
            id: videoId
        };
        addMutation.mutate(modelUpdate, {
            onSuccess: (res) => {
                if (res.status !== 200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    setIsLoadingEdit(true);
                    toast.success(messageToast.messageToastUpdateSuccess);
                    queryClient.setQueryData(videoId, modelUpdate);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    const isLoading = postItem.isLoading || listCate.isLoading;
    const isError = postItem.isError || listCate.isError;
    const error = postItem.isError ? postItem.error : listCate.error;

    // let menuData = [];
    // if (!listCate.isLoading && !listCate.isError) {
    //     menuData = listCate.data
    //         ? menuData.concat(
    //             listCate.data.data.$values.filter(
    //                 (x) => x.dataType === dataTypeCategory.video
    //             )
    //         )
    //         : [];
    // }

    let listMenu = [];
    if (!listCate.isLoading && !listCate.isError && listCate.data) {
        listMenu = listCate.data.data.$values;
    }

    return (
        <Card>
            <Card.Header as="h5">Sửa thông tin video clip</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : postItem.data == null ? (
                    <div>Video clip không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            title:
                                postItem.data && postItem.data.title
                                    ? postItem.data.title
                                    : '',
                            titleEn:
                                postItem.data && postItem.data.titleEn
                                    ? postItem.data.titleEn
                                    : '',
                            description:
                                postItem.data && postItem.data.description
                                    ? postItem.data.description
                                    : '',
                            descriptionEn:
                                postItem.data && postItem.data.descriptionEn
                                    ? postItem.data.descriptionEn
                                    : '',
                            keywords:
                                postItem.data && postItem.data.keywords
                                    ? postItem.data.keywords
                                    : '',
                            ogTitle:
                                postItem.data && postItem.data.ogTitle
                                    ? postItem.data.ogTitle
                                    : '',
                            ogDescription:
                                postItem.data && postItem.data.ogDescription
                                    ? postItem.data.ogDescription
                                    : '',
                            articleTag:
                                postItem.data && postItem.data.articleTag
                                    ? postItem.data.articleTag
                                    : '',
                            content:
                                postItem.data && postItem.data.content
                                    ? postItem.data.content
                                    : '',
                            contentEn:
                                postItem.data && postItem.data.contentEn
                                    ? postItem.data.contentEn
                                    : '',
                            videoLink:
                                postItem.data && postItem.data.videoLink
                                    ? postItem.data.videoLink
                                    : '',
                            isFocus:
                                postItem.data && postItem.data.isFocus
                                    ? postItem.data.isFocus
                                    : false,
                            isHighlight: postItem.data && postItem.data.isHighlight
                                ? postItem.data.isHighlight
                                : false,
                            author: postItem.data && postItem.data.author
                                ? postItem.data.author
                                : '',
                            sourceLink: postItem.data && postItem.data.sourceLink
                                ? postItem.data.sourceLink
                                : '',
                            imageThumbnail:
                                postItem.data && postItem.data.imageThumbnail
                                    ? postItem.data.imageThumbnail
                                    : '',
                            imagePath:
                                postItem.data && postItem.data.imagePath
                                    ? postItem.data.imagePath
                                    : '',
                            postStatus: postItem.data
                                ? objectStatusPost.find(
                                    (x) => x.value === postItem.data.status
                                )
                                : '',
                            categoryId:
                                postItem.data.categoryId === NIL_UUID
                                    ? 'null'
                                    : postItem.data.categoryId,
                            publishTime:
                                postItem.data && postItem.data.publishTime
                                    ? new Date(postItem.data.publishTime)
                                    : new Date(),
                            virtualViews: postItem.data && postItem.data.virtualViews
                                ? postItem.data.virtualViews
                                : 0,
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Form.Control
                                                    placeholder="Tên"
                                                    type="text"
                                                    name="title"
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.title}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.title}
                                                </Form.Control.Feedback>
                                            </TabPanel>
                                            <TabPanel>
                                                <Form.Control
                                                    placeholder="Tên"
                                                    type="text"
                                                    name="titleEn"
                                                    value={values.titleEn}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.titleEn}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                        {/* <Form.Control
                                            placeholder="Tên"
                                            type="text"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Danh mục *
                                    </Form.Label>
                                    <Col sm="10">
                                        <SelectTree
                                            data={listMenu}
                                            typeChoose={dataTypeCategory.video}
                                            showSearchBox
                                            Placeholder="Danh mục"
                                            positionExpand="right"
                                            singleValueSelected={listMenu.find(
                                                (x) =>
                                                    x.id === values.categoryId
                                            )}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'categoryId',
                                                    value.id
                                                );
                                            }}
                                            isInvalid={!!errors.categoryId}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.categoryId}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái bài viết *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="postStatus"
                                            id="postStatus"
                                            placeholder="Chọn trạng thái bài viết"
                                            value={values.postStatus}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'postStatus',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            options={objectStatusFilter}
                                            styles={
                                                errors.postStatus !== undefined
                                                    ? styleSelectError
                                                    : styleSelectDefault
                                            }
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                        >
                                            {errors.postStatus}
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Đường dẫn video *
                                    </Form.Label>
                                    <Col sm="10">
                                        <VideoInput
                                            isInvalid={!!errors.videoLink}
                                            errorsValue={errors.videoLink}
                                            value={values.videoLink}
                                            onChangeProp={(value) => {
                                                setFieldValue('videoLink', value);
                                            }}
                                        />
                                        {/* <Form.Control
                                            placeholder=""
                                            type="text"
                                            name="videoLink"
                                            value={values.videoLink}
                                            onChange={handleChange}
                                            isInvalid={!!errors.videoLink}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.videoLink}
                                        </Form.Control.Feedback> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh đại diện 
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            values={
                                                values.imageThumbnail !== ''
                                                    ? [
                                                        BaseGatewayImage +
                                                        values.imageThumbnail
                                                    ]
                                                    : []
                                            }
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                setFieldValue(
                                                    'imageThumbnail',
                                                    files.length > 0
                                                        ? files[0].thumbPath
                                                        : '',
                                                    true
                                                );
                                                setFieldValue(
                                                    'imagePath',
                                                    files.length > 0
                                                        ? files[0].filePath
                                                        : '',
                                                    true
                                                );
                                            }}
                                        />
                                        {/* <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.imageThumbnail}
                                        </div> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thời gian xuất bản
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <DatePicker
                                            selected={values.publishTime}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    'publishTime',
                                                    date,
                                                    true
                                                );
                                            }}
                                            timeInputLabel="Time:"
                                            dateFormat={DATE_TIME_REACT_FORMAT}
                                            showTimeInput
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ReactPlayer
                                            url={values.videoLink}
                                            playing
                                            controls
                                            light
                                            muted
                                        />
                                    </Col>
                                </Form.Group> */}

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Editor
                                                    name="description"
                                                    value={values.description}
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            'description',
                                                            value
                                                        );
                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Editor
                                                    name="descriptionEn"
                                                    value={values.descriptionEn}
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            'descriptionEn',
                                                            value
                                                        );
                                                    }}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                        {/* <Editor
                                            name="description"
                                            value={values.description}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'description',
                                                    value
                                                );
                                            }}
                                        /> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nội dung
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Editor
                                                    name="content"
                                                    value={values.content}
                                                    onChange={(value) => {
                                                        setFieldValue('content', value);
                                                        if (values.imagePath === "" || values.imageThumbnail === "") {
                                                            var divContent = document.createElement('div');
                                                            divContent.innerHTML = value;
                                                            var imgs = divContent.querySelectorAll('img');
                                                            if (imgs && imgs.length > 0) {
                                                                for (let i = 0; i < imgs.length; i++) {
                                                                    if (imgs[i].getAttribute("src")) {
                                                                        console.log("element.getAttribute", imgs[i].getAttribute("src"));
                                                                        setFieldValue(
                                                                            'imagePath',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        setFieldValue(
                                                                            'imageThumbnail',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        return;
                                                                    }
                                                                }
                                                            }

                                                        }
                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Editor
                                                    name="contentEn"
                                                    value={values.contentEn}
                                                    onChange={(value) => {
                                                        setFieldValue('contentEn', value);
                                                        if (values.imagePath === "" || values.imageThumbnail === "") {
                                                            var divContent = document.createElement('div');
                                                            divContent.innerHTML = value;
                                                            var imgs = divContent.querySelectorAll('img');
                                                            if (imgs && imgs.length > 0) {
                                                                for (let i = 0; i < imgs.length; i++) {
                                                                    if (imgs[i].getAttribute("src")) {
                                                                        setFieldValue(
                                                                            'imagePath',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        setFieldValue(
                                                                            'imageThumbnail',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        return;
                                                                    }
                                                                }
                                                            }

                                                        }
                                                    }}
                                                />
                                            </TabPanel>
                                        </Tabs>

                                        {/* <Editor
                                            name="content"
                                            value={values.content}
                                            onChange={(value) => {
                                                setFieldValue('content', value);
                                            }}
                                        /> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tác giả
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tác giả"
                                            type="text"
                                            name="author"
                                            value={values.author}
                                            onChange={handleChange}
                                            isInvalid={!!errors.author}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nguồn
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Nguồn"
                                            type="text"
                                            name="sourceLink"
                                            value={values.sourceLink}
                                            onChange={handleChange}
                                            isInvalid={!!errors.sourceLink}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Là tiêu điểm
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary1"
                                            type="checkbox"
                                            placeholder="Là tiêu điểm"
                                            name="isFocus"
                                            checked={values.isFocus}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary1">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nổi bật
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary2"
                                            type="checkbox"
                                            placeholder="Nổi bật"
                                            name="isHighlight"
                                            checked={values.isHighlight}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary2">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Lượt view ảo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="virtualViews"
                                            placeholder=""
                                            value={values.virtualViews}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Từ khóa Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Từ khóa"
                                            type="text"
                                            name="keywords"
                                            value={values.keywords}
                                            onChange={handleChange}
                                            isInvalid={!!errors.keywords}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.keywords}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tiêu đề Og"
                                            type="text"
                                            name="ogTitle"
                                            value={values.ogTitle}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogTitle}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogTitle}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Mô tả Og"
                                            type="text"
                                            name="ogDescription"
                                            value={values.ogDescription}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogDescription}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogDescription}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tag Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tag bài báo"
                                            type="text"
                                            name="articleTag"
                                            value={values.articleTag}
                                            onChange={handleChange}
                                            isInvalid={!!errors.articleTag}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.articleTag}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Sửa
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
                {/* <ReactSpinner loadings={isLoadingEdit} /> */}
            </Card.Body>
        </Card>
    );
};

export default VideoEdit;
