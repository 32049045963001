import { consoleLog, getToken } from './helper';

function FormGet(model) {
    const formGet = [];
    Object.keys(model).forEach(function (property) {
        if (Array.isArray(model[property])) {
            model[property].forEach((element) => {
                const encodedKey = encodeURIComponent(property);
                const encodedValue = encodeURIComponent(element);
                formGet.push(`${encodedKey}=${encodedValue}`);
            });
        } else {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(model[property]);
            formGet.push(`${encodedKey}=${encodedValue}`);
        }
    });
    return formGet.join('&');
}

function Header(ContentType = '') {
    const token = getToken();
    const headers = new Headers();
    if (token) {
        headers.append('Authorization', `bearer ${token}`);
    }
    if (ContentType.length > 0) {
        headers.append('Content-Type', ContentType);
    }
    return headers;
}

export async function Post(api, param = null, model, convertToJson = false) {
    let API = api;
    if (param && param.toString().length > 0) {
        API = `${API}/${param}`;
    }
    return fetch(API, {
        method: 'POST',
        headers: Header('application/json'),
        body: JSON.stringify(model),
        credentials: 'include'
    })
        .then((res) => {
            if (convertToJson) {
                if (res.status === 200 || res.status === 400 || res.status === 201) {
                    if (res.json() != null) return res.json();
                }
            }
            return res;
        })
        .catch((error) => {
            consoleLog(error);
            return null;
        });
}

export function Get(api, param = null, model = null) {
    let API = api;
    if (param) {
        API = `${API}/${param}`;
    }
    if (model) {
        API = `${API}?${FormGet(model)}`;
    }

    return fetch(API, {
        method: 'GET',
        headers: Header('application/json'),
        credentials: 'include'
    })
        .then((res) => {
            if (res.ok) return res.json();
            return null;
        })
        .catch((error) => {
            consoleLog(error);
            return null;
        });
}

export function DeleteSingle(api, param, model = null) {
    let API = api;
    if (param) {
        API = `${API}/${param}`;
    }
    if (model) {
        API = `${API}?${FormGet(model)}`;
    }
    return fetch(API, {
        method: 'DELETE',
        headers: Header('application/json'),
        body: model ? JSON.stringify(model) : ''
    })
        .then((res) => res)
        .catch((error) => {
            consoleLog(error);
            return error;
        });
}

export function Put(api, param = null, model = null) {
    let API = api;
    if (param) {
        API = `${API}/${param}`;
    }
    return fetch(API, {
        method: 'PUT',
        headers: Header('application/json'),
        body: model ? JSON.stringify(model) : '',
        credentials: 'include'
    })
        .then((res) => res)
        .catch((error) => {
            consoleLog(error);
            return error;
        });
}
export async function UploadFile(api, param, data) {
    let API = api;
    if (param) {
        API = `${API}?${FormGet(param)}`;
    }
    const requestOptions = {
        method: 'POST',
        body: data,
        headers: Header(''),
        credentials: 'include'
    };
    return fetch(API, requestOptions)
        .then(async (res) => res)
        .catch((error) => {
            return error;
        });
}
