import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '../constants';
import { TOKEN_KEY } from '../../utils/define';
import { Post, Put } from '../../utils/httpProvider';
import { checkExpToken } from '../../utils/helper';
import { AUTH_API } from '../../utils/apiUrl';

//  Action này sử dụng để call API
export const loginUser = createAsyncThunk(
    AUTH.LOGIN_THUNK,
    async (model, { rejectWithValue, fulfillWithValue }) => {
        return Post(AUTH_API.LOGIN_API, null, model, false)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(await response.json());
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);
export const changePasswordAction = createAsyncThunk(
    AUTH.CHANGE_PASSWORD,
    async (model) => {
        return Put(AUTH_API.CHANGE_PASSWORD_API, null, model)
            .then(async (response) => {
                return response.json();
            })
            .catch((error) => error);
    }
);

const initialState = {
    // isLoggedIn: !!localStorage.getItem(TOKEN_KEY),
    isLoggedIn: checkExpToken(),
    token: localStorage.getItem(TOKEN_KEY),
    errorLogin: null
};

export const authSlice = createSlice({
    name: AUTH.NAME_SLICE,
    initialState,
    reducers: {
        logoutUser: (state) => {
            //  Action xử lý state trực tiếp
            localStorage.removeItem(TOKEN_KEY);
            state.isLoggedIn = false;
            state.token = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.fulfilled, (state, action) => {
                //  xử lý khi call API thành công
                if (action.payload && action.payload.accessToken) {
                    localStorage.setItem(TOKEN_KEY, action.payload.accessToken);
                    state.isLoggedIn = true;
                    state.token = action.payload.accessToken;
                    state.errorLogin = null;
                }
            })
            .addCase(loginUser.rejected, (state, action) => {
                //  xử lý khi call API bị lỗi
                state.isLoggedIn = false;
                state.errorLogin =
                    action.payload.errors && action.payload.errors.content[0]
                        ? action.payload.errors.content[0]
                        : null;
                state.token = null;
            });
    }
});

export const { logoutUser } = authSlice.actions;

export default authSlice.reducer;
