import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VideoInput from '@app/components/video-input/VideoInput';
import InputFile from '@app/components/input-file/InputFile';
import { BaseGatewayImage } from '@app/utils/apiUrl';
import {
    messageError,
    messageToast,
    acceptImage
} from '../../utils/define';
import { addAccountProjectAction, getByIdAccountAction } from '../../store/reducers/account';
import { isValidUrl } from '@app/utils/helper';

const schema = yup.object().shape({
    title: yup.string().required(messageError.emptyMessage),
    content: yup.string().required(messageError.emptyMessage),
});

const AccountProjectAdd = () => {
    const [idAccount, setIdAccount] = useState('');
    const [imageThumbPath, setImageThumbPath] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const paramUrl = new URLSearchParams(window.location.search);
        if (paramUrl.get('id') != null) {
            const id = paramUrl.get('id');
            setIdAccount(id);
            dispatch(getByIdAccountAction(id));
        }
    }, [dispatch]);

    const handleCreate = async (values, actions) => {
        const model = { ...values, accountId: idAccount };
        try {
            const result = await dispatch(addAccountProjectAction(model));
            if (result.payload && result.payload.errors) {
                toast.error("Đã có lỗi xảy ra");
            } else {
                toast.success(messageToast.messageToastCreateSuccess);
                history.goBack();
            }
        } catch (error) {
            toast.error(messageToast.messageToastTryCatch);
        }
    };

    return (
        <Card>
            <Card.Header as="h5">Thêm tài khoản</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={handleCreate}
                    initialValues={{
                        title: '',
                        content: '',
                        linkDocument: '',
                        imagePath: '',
                        imageThumbnail: '',
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => {
                        useEffect(() => {
                            if (imageThumbPath) {
                                setFieldValue('imageThumbnail', imageThumbPath, true);
                            }
                        }, [imageThumbPath, setFieldValue]);

                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tiêu đề"
                                            type="text"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nội dung
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Nội dung"
                                            name="content"
                                            value={values.content}
                                            onChange={handleChange}
                                            isInvalid={!!errors.content}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.content}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Video
                                    </Form.Label>
                                    <Col sm="10">
                                        <VideoInput
                                            isInvalid={!!errors.linkDocument}
                                            errorsValue={errors.linkDocument}
                                            value={values.linkDocument}
                                            onChangeProp={(value) => {
                                                setFieldValue('linkDocument', value);
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.linkDocument}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            labelImage="Upload ảnh"
                                            enableReinitialize={true}
                                            values={
                                                values.imagePath
                                                    ? [
                                                          isValidUrl(values.imagePath)
                                                              ? values.imagePath
                                                              : BaseGatewayImage + values.imagePath
                                                      ]
                                                    : []
                                            }
                                            name="imagePath"
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                if (files.length > 0) {
                                                    setImageThumbPath(files[0].thumbPath);
                                                    setFieldValue('imagePath', files[0].filePath, true);
                                                }
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh Thumbnail
                                    </Form.Label>
                                    <Col sm="10">
                                        {imageThumbPath ? (
                                            <img
                                                height={200}
                                                width={200}
                                                src={BaseGatewayImage + imageThumbPath}
                                                alt="Ảnh"
                                            />
                                        ) : (
                                            <img
                                                height={200}
                                                width={200}
                                                src={BaseGatewayImage + values.imageThumbnail}
                                                alt="Ảnh"
                                            />
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Thêm
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() => history.goBack()}
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        );
                    }}
                </Formik>
            </Card.Body>
        </Card>
    );
};

export default AccountProjectAdd;
