import React from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import { Get, Post } from '@app/utils/httpProvider';
import { CATEGORY_API, POST_API } from '@app/utils/apiUrl';
import { useMutation, useQuery } from 'react-query';
import DatePicker from 'react-datepicker';
import Editor from '@app/components/editor/Editor';
import Select from 'react-select';
import SelectTree from '@app/components/select-tree/SelectTree';
import { dateTimeToUTCRequest, getInfomationAccountFromToken } from '@app/utils/helper';
import {
    messageError,
    messageToast,
    acceptImage,
    objectStatusPost,
    dataTypeCategory,
    styleSelectDefault,
    styleSelectError,
    DATE_TIME_REACT_FORMAT,
    role,
    postStatus
} from '@app/utils/define';
import VideoInput from '@app/components/video-input/VideoInput';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const schema = yup.object().shape({
    title: yup.string().required(messageError.emptyMessage),
    postStatus: yup.object().required(messageError.emptyMessage),
    categoryId: yup.string().required(messageError.emptyMessage),
    // videoLink: yup.string().required(messageError.emptyMessage),
    imageThumbnail: yup.string().required(messageError.emptyMessage),
});

const PersonAdd = () => {
    const history = useHistory();

    const addMutation = useMutation((value) =>
        Post(POST_API.ADD_POST, null, value, false)
    );

    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY, null, { PageSize: 100 })
    );

    let listMenu = [];
    if (listCate.data) {
        listMenu = listCate.data.data.$values;
    }

    let objectStatusFilter;
    const inforAccount = getInfomationAccountFromToken();
    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        if (userRole.includes(role.Admin) || userRole.includes(role.Manager) || userRole.includes(role.Publisher)) {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all);
        } else {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all && x.value !== postStatus.release && x.value !== postStatus.refuse);
        }
    }


    const handleCreate = async (values) => {
        const model = { ...values };
        model.categoryId = values.categoryId;
        model.dataType = listMenu.find(
            (x) => x.id === values.categoryId
        ).dataType;
        model.status = values.postStatus.value;
        model.publishTime = dateTimeToUTCRequest(values.publishTime);

        addMutation.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== 200) {
                    toast.error(messageToast.messageToastCreateError);
                } else {
                    toast.success(messageToast.messageToastCreateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    return (
        <Card>
            <Card.Header as="h5">Thêm nhân sự </Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => handleCreate(values)}
                    initialValues={{
                        title: '',
                        titleEn: '',
                        description: '',
                        descriptionEn: '',
                        keywords: '',
                        ogTitle: '',
                        ogDescription: '',
                        articleTag: '',
                        content: '',
                        contentEn: '',
                        videoLink: '',
                        imagePath: '',
                        imageThumbnail: '',
                        virtualViews: 0,
                        orderNumber:0,
                        isFocus: false,
                        sourceLink: '',
                        isHighlight: false,
                        author: '',
                        categoryId: '',
                        postStatus: '',
                        publishTime: new Date()
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tiêu đề *
                                </Form.Label>
                                <Col sm="10">
                                    <Tabs>
                                        <TabList>
                                            <Tab>Tiếng việt</Tab>
                                            <Tab>Tiếng Anh</Tab>
                                        </TabList>
                                        <TabPanel>
                                            <Form.Control
                                                placeholder="Tên"
                                                type="text"
                                                name="title"
                                                value={values.title}
                                                onChange={handleChange}
                                                isInvalid={!!errors.title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.title}
                                            </Form.Control.Feedback>
                                        </TabPanel>
                                        <TabPanel>
                                            <Form.Control
                                                placeholder="Tên"
                                                type="text"
                                                name="titleEn"
                                                value={values.titleEn}
                                                onChange={handleChange}
                                                isInvalid={!!errors.titleEn}
                                            />
                                        </TabPanel>
                                    </Tabs>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Danh mục *
                                </Form.Label>
                                <Col sm="10">
                                    <SelectTree
                                        data={listMenu}
                                        typeChoose={dataTypeCategory.person}
                                        showSearchBox
                                        Placeholder="Danh mục"
                                        positionExpand="right"
                                        singleValueSelected={values.id}
                                        onChange={(value) => {
                                            setFieldValue(
                                                'categoryId',
                                                value.id
                                            );
                                        }}
                                        isInvalid={!!errors.categoryId}
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.categoryId}
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Trạng thái bài viết *
                                </Form.Label>
                                <Col sm="10">
                                    <Select
                                        name="postStatus"
                                        id="postStatus"
                                        placeholder="Chọn trạng thái bài viết"
                                        value={values.postStatus}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: 'postStatus',
                                                    value: selectedOption
                                                }
                                            };
                                            handleChange(event);
                                        }}
                                        options={objectStatusFilter}
                                        styles={
                                            errors.postStatus !== undefined
                                                ? styleSelectError
                                                : styleSelectDefault
                                        }
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                    >
                                        {errors.postStatus}
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Thứ tự
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="orderNumber"
                                        placeholder=""
                                        value={values.orderNumber}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Ảnh đại diện *
                                </Form.Label>
                                <Col sm="10">
                                    <InputFile
                                        name="imageThumbnail"
                                        accept={acceptImage}
                                        onChange={(files) => {
                                            if (files.length > 0) {
                                                setFieldValue(
                                                    'imagePath',
                                                    files[0].filePath,
                                                    true
                                                );
                                                setFieldValue(
                                                    'imageThumbnail',
                                                    files[0].thumbPath,
                                                    true
                                                );
                                            }
                                        }}
                                    />

                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#dc3545'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {errors.imageThumbnail}
                                    </div>

                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.parentId}
                                    </Form.Control.Feedback> */}
                                </Col>
                            </Form.Group>


                            {/* <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Đường dẫn video *
                                </Form.Label>
                                <Col sm="10">
                                    <VideoInput
                                        isInvalid={!!errors.videoLink}
                                        errorsValue={errors.videoLink}
                                        value={values.videoLink}
                                        onChangeProp={(value) => {
                                            setFieldValue('videoLink', value);
                                        }}
                                    />
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Thời gian xuất bản
                                </Form.Label>
                                <Col sm="10" className="icheck-primary">
                                    <DatePicker
                                        selected={values.publishTime}
                                        onChange={(date) =>
                                            setFieldValue(
                                                'publishTime',
                                                date,
                                                true
                                            )
                                        }
                                        timeInputLabel="Time:"
                                        dateFormat={DATE_TIME_REACT_FORMAT}
                                        showTimeInput
                                        className="form-control"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tóm tắt
                                </Form.Label>
                                <Col sm="10">
                                    <Tabs>
                                        <TabList>
                                            <Tab>Tiếng việt</Tab>
                                            <Tab>Tiếng Anh</Tab>
                                        </TabList>
                                        <TabPanel>
                                            <Editor
                                                name="description"
                                                value={values.description}
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        'description',
                                                        value
                                                    );
                                                }}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <Editor
                                                name="descriptionEn"
                                                value={values.descriptionEn}
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        'descriptionEn',
                                                        value
                                                    );
                                                }}
                                            />
                                        </TabPanel>
                                    </Tabs>
                                    {/* <Editor
                                        name="description"
                                        value={values.description}
                                        onChange={(value) => {
                                            setFieldValue('description', value);
                                        }}
                                    /> */}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Nội dung
                                </Form.Label>
                                <Col sm="10">
                                    <Tabs>
                                        <TabList>
                                            <Tab>Tiếng việt</Tab>
                                            <Tab>Tiếng Anh</Tab>
                                        </TabList>
                                        <TabPanel>
                                            <Editor
                                                name="content"
                                                value={values.content}
                                                onChange={async (value) => {
                                                    setFieldValue('content', value);
                                                    if (values.imagePath === "" || values.imageThumbnail === "") {
                                                        var divContent = document.createElement('div');
                                                        divContent.innerHTML = value;
                                                        var imgs = divContent.querySelectorAll('img');
                                                        if (imgs && imgs.length > 0) {
                                                            for (let i = 0; i < imgs.length; i++) {
                                                                if (imgs[i].getAttribute("src")) {
                                                                    console.log("element.getAttribute", imgs[i].getAttribute("src"));
                                                                    await setFieldValue(
                                                                        'imagePath',
                                                                        imgs[i].getAttribute("src"),
                                                                        true
                                                                    );
                                                                    await setFieldValue(
                                                                        'imageThumbnail',
                                                                        imgs[i].getAttribute("src"),
                                                                        true
                                                                    );
                                                                    break;
                                                                }
                                                            }
                                                        }

                                                    }

                                                }}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <Editor
                                                name="contentEn"
                                                value={values.contentEn}

                                                onChange={async (value) => {
                                                    setFieldValue('contentEn', value);
                                                    if (values.imagePath === "" || values.imageThumbnail === "") {
                                                        var divContent = document.createElement('div');
                                                        divContent.innerHTML = value;
                                                        var imgs = divContent.querySelectorAll('img');
                                                        if (imgs && imgs.length > 0) {
                                                            for (let i = 0; i < imgs.length; i++) {
                                                                if (imgs[i].getAttribute("src")) {
                                                                    console.log("element.getAttribute", imgs[i].getAttribute("src"));
                                                                    await setFieldValue(
                                                                        'imagePath',
                                                                        imgs[i].getAttribute("src"),
                                                                        true
                                                                    );
                                                                    await setFieldValue(
                                                                        'imageThumbnail',
                                                                        imgs[i].getAttribute("src"),
                                                                        true
                                                                    );
                                                                    break;
                                                                }
                                                            }
                                                        }

                                                    }

                                                }}
                                            />
                                        </TabPanel>
                                    </Tabs>
                                    {/* <Editor
                                        name="content"
                                        value={values.content}
                                        onChange={(value) => {
                                            setFieldValue('content', value);
                                        }}
                                    /> */}
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tác giả
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tác giả"
                                        type="text"
                                        name="author"
                                        value={values.author}
                                        onChange={handleChange}
                                        isInvalid={!!errors.author}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Nguồn
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Nguồn"
                                        type="text"
                                        name="sourceLink"
                                        value={values.sourceLink}
                                        onChange={handleChange}
                                        isInvalid={!!errors.sourceLink}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Là tiêu điểm
                                </Form.Label>
                                <Col sm="10" className="icheck-primary">
                                    <Form.Control
                                        id="checkboxPrimary1"
                                        type="checkbox"
                                        placeholder="Là tiêu điểm"
                                        name="isFocus"
                                        checked={values.isFocus}
                                        onClick={handleChange}
                                    />
                                    <Form.Label htmlFor="checkboxPrimary1">
                                        {' '}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Nổi bật
                                </Form.Label>
                                <Col sm="10" className="icheck-primary">
                                    <Form.Control
                                        id="checkboxPrimary2"
                                        type="checkbox"
                                        placeholder="Nổi bật"
                                        name="isHighlight"
                                        checked={values.isHighlight}
                                        onClick={handleChange}
                                    />
                                    <Form.Label htmlFor="checkboxPrimary2">
                                        {' '}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Lượt view ảo
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="number"
                                        name="virtualViews"
                                        placeholder=""
                                        value={values.virtualViews}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Từ khóa Seo
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Từ khóa"
                                        type="text"
                                        name="keywords"
                                        value={values.keywords}
                                        onChange={handleChange}
                                        isInvalid={!!errors.keywords}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.keywords}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tiêu đề Seo
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tiêu đề Og"
                                        type="text"
                                        name="ogTitle"
                                        value={values.ogTitle}
                                        onChange={handleChange}
                                        isInvalid={!!errors.ogTitle}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ogTitle}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Mô tả Seo
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Mô tả Og"
                                        type="text"
                                        name="ogDescription"
                                        value={values.ogDescription}
                                        onChange={handleChange}
                                        isInvalid={!!errors.ogDescription}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ogDescription}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-2 col-form-label">
                                    Tag Seo
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        placeholder="Tag bài báo"
                                        type="text"
                                        name="articleTag"
                                        value={values.articleTag}
                                        onChange={handleChange}
                                        isInvalid={!!errors.articleTag}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.articleTag}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col md={{ span: 10, offset: 2 }}>
                                    <ButtonGroup className="mb-2">
                                        <Button
                                            type="submit"
                                            theme="primary"
                                        >
                                            Thêm
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            onClick={() =>
                                                history.goBack()
                                            }
                                            theme="secondary"
                                        >
                                            Hủy
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );
};

export default PersonAdd;
