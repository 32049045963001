import React, { useState } from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Get, Post } from '@app/utils/httpProvider';
import { useQuery, useMutation } from 'react-query';
import DatePicker from 'react-datepicker';
import Editor from '@app/components/editor/Editor';
import SelectTree from '@app/components/select-tree/SelectTree';
import { dateTimeToUTCRequest, getInfomationAccountFromToken, isValidUrl } from '@app/utils/helper';
import InputFile from '@app/components/input-file/InputFile';
import {
    messageError,
    messageToast,
    postStatus,
    status200,
    dataTypeCategory,
    role,
    objectStatusPost,
    styleSelectError,
    styleSelectDefault,
    acceptImage,
    DATE_TIME_REACT_FORMAT
} from '../../utils/define';
import { NEWSCRAWLER_API, CRAWLER_API, POST_API, CATEGORY_API, BaseGatewayImage } from '../../utils/apiUrl';
import ReactSpinner from '../../components/reactSpinner/ReactSpinner';

const schema = yup.object().shape({
    title: yup.string().required(messageError.emptyMessage),
    imageThumbnail: yup.string().required(messageError.emptyMessage),
    categoryId: yup.string().required(messageError.emptyMessage),
    postStatus: yup.object().required(messageError.emptyMessage),
    // sourceLink: yup.string().required(messageError.emptyMessage),
});

const NewsCrawlerEdit = () => {
    const history = useHistory();
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));

    const newsCrawlerItem = useQuery(['newsCrawlerItem'], () =>
        // Get(NEWSCRAWLER_API.GET_ITEM_NEWSCRAWLER, paramUrl.get('id'))
        Get(CRAWLER_API.GETBYID_NEWCONTENT, paramUrl.get('id'))
    );

    const listCate = useQuery(['cateData'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY)
    );

    const addFunc = useMutation((value) =>
        Post(POST_API.ADD_POST, null, value, false)
    );

    const handleEdit = async (values) => {
        const model = { ...values };
        // model.status = postStatus.draft;
        model.dataType = listCate.data.data.$values.find(
            (x) => x.id === values.categoryId
        ).dataType;
        model.categoryId =
            values.categoryId === 'null' ? '' : values.categoryId;
        model.publishTime = dateTimeToUTCRequest(values.publishTime);
        model.status = values.postStatus.value;

        addFunc.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    setIsLoadingEdit(true);
                    toast.success(messageToast.messageToastUpdateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    const isLoading = newsCrawlerItem.isLoading || listCate.isLoading;
    const isError = newsCrawlerItem.isError || listCate.isError;
    const error = newsCrawlerItem.isError
        ? newsCrawlerItem.error
        : listCate.error;

    // const listMenu = listCate.data ? listCate.data.data.$values : [];

    const listMenu = listCate.data
        ? listCate.data.data.$values.filter(
            (x) => x.dataType === dataTypeCategory.information
        )
        : [];

    // let listMenu = [
    //     {
    //         name: 'Danh mục gốc',
    //         id: 'null',
    //         parentId: NIL_UUID
    //     }
    // ];

    // listMenu = listMenu.concat(listCate.data ? listCate.data.data.$values : []);

    let objectStatusFilter;
    const inforAccount = getInfomationAccountFromToken();
    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        if (userRole.includes(role.Admin) || userRole.includes(role.Manager) || userRole.includes(role.Publisher)) {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all);
        } else {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all && x.value !== postStatus.release && x.value !== postStatus.refuse);
        }
    }
    const getImgContent = (content) => {
        let urlImg = "";
        console.log("content", content);
        var divContent = document.createElement('div', { id: "content_cralwer" });
        divContent.innerHTML = content;
        var imgs = divContent.querySelectorAll('img');
        if (imgs && imgs.length > 0) {
            for (let i = 0; i < imgs.length; i++) {
                if (imgs[i].getAttribute("src")) {
                    urlImg = imgs[i].getAttribute("src");
                }
            }
        }
        return urlImg;
    }

    // React.useEffect(() => {

    //     return () => {
    //         var divContent = document.getElementById('content_cralwer');
    //         if (divContent) {
    //             document.parentNode.removeChild(divContent);
    //         }
    //     }
    // }, []);

    return (
        <Card>
            <Card.Header as="h5">Sửa tin lấy về </Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : newsCrawlerItem.data == null ? (
                    <div>Tin tức không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            // baseUrl:
                            //     newsCrawlerItem.data &&
                            //         newsCrawlerItem.data.baseUrl
                            //         ? newsCrawlerItem.data.baseUrl
                            //         : '',
                            sourceLink:
                                newsCrawlerItem.data &&
                                    newsCrawlerItem.data.url
                                    ? newsCrawlerItem.data.url
                                    : '',
                            title:
                                newsCrawlerItem.data &&
                                    newsCrawlerItem.data.title
                                    ? newsCrawlerItem.data.title
                                    : '',
                            // category:
                            //     newsCrawlerItem.data &&
                            //         newsCrawlerItem.data.category
                            //         ? newsCrawlerItem.data.category
                            //         : '',
                            description:
                                newsCrawlerItem.data &&
                                    newsCrawlerItem.data.description
                                    ? newsCrawlerItem.data.description
                                    : '',
                            content:
                                newsCrawlerItem.data &&
                                    newsCrawlerItem.data.content
                                    ? newsCrawlerItem.data.content
                                    : '',
                            fromWhichSource:
                                newsCrawlerItem.data &&
                                    newsCrawlerItem.data.fromWhichSource
                                    ? newsCrawlerItem.data.fromWhichSource
                                    : '',
                            postStatus: '',
                            publishTime: new Date(),
                            imagePath: getImgContent(newsCrawlerItem.data &&
                                newsCrawlerItem.data.content
                                ? newsCrawlerItem.data.content
                                : ''),
                            imageThumbnail: getImgContent(newsCrawlerItem.data &&
                                newsCrawlerItem.data.content
                                ? newsCrawlerItem.data.content
                                : ''),
                            author: '',
                            isFocus: false,
                            isHighlight: false,
                            virtualViews: 0,
                            keywords: '',
                            ogTitle: '',
                            ogDescription: '',
                            articleTag: '',
                            dateOfPost: newsCrawlerItem.data &&
                            newsCrawlerItem.data.dateOfPost
                            ? newsCrawlerItem.data.dateOfPost
                            : '',
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Tiêu đề"
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Danh mục *
                                    </Form.Label>
                                    <Col sm="10">
                                        <SelectTree
                                            data={listMenu}
                                            showSearchBox
                                            Placeholder="Danh mục"
                                            positionExpand="right"
                                            singleValueSelected={values.id}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'categoryId',
                                                    value.id
                                                );
                                            }}
                                            isInvalid={!!errors.categoryId}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.categoryId}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái bài viết *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="postStatus"
                                            id="postStatus"
                                            placeholder="Chọn trạng thái bài viết"
                                            value={values.postStatus}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'postStatus',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            // options={objectStatusPost}
                                            options={objectStatusFilter}
                                            styles={
                                                errors.postStatus !== undefined
                                                    ? styleSelectError
                                                    : styleSelectDefault
                                            }
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.postStatus}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tóm tắt
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="description"
                                            value={values.description}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'description',
                                                    value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nội dung
                                    </Form.Label>
                                    <Col sm="10">
                                        <Editor
                                            name="content"
                                            value={values.content}
                                            onChange={(value) => {
                                                setFieldValue('content', value);
                                                var divContent = document.createElement('div');
                                                divContent.innerHTML = value;
                                                var imgs = divContent.querySelectorAll('img');
                                                if (imgs && imgs.length > 0) {
                                                    for (let i = 0; i < imgs.length; i++) {
                                                        if (imgs[i].getAttribute("src")) {
                                                            console.log("element.getAttribute", imgs[i].getAttribute("src"));
                                                            setFieldValue(
                                                                'imagePath',
                                                                imgs[i].getAttribute("src"),
                                                                true
                                                            );
                                                            setFieldValue(
                                                                'imageThumbnail',
                                                                imgs[i].getAttribute("src"),
                                                                true
                                                            );
                                                            break;
                                                        }
                                                    }
                                                }

                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh đại diện*
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            enableReinitialize={true}
                                            values={values.imageThumbnail ? [isValidUrl(values.imageThumbnail) ? values.imageThumbnail : BaseGatewayImage + values.imageThumbnail] : []}
                                            name="imageThumbnail"
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                if (files.length > 0) {
                                                    setFieldValue(
                                                        'imagePath',
                                                        files[0].filePath,
                                                        true
                                                    );
                                                    setFieldValue(
                                                        'imageThumbnail',
                                                        files[0].thumbPath,
                                                        true
                                                    );
                                                }
                                            }}
                                        />

                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.imageThumbnail}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Time xuất bản/ Bản gốc
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Time xuất bản/ Bản gốc"
                                            type="text"
                                            name="dateOfPost"
                                            value={values.dateOfPost}
                                            onChange={(value) => {
                                            }}
                                            isInvalid={!!errors.author}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thời gian xuất bản
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <DatePicker
                                            selected={values.publishTime}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    'publishTime',
                                                    date,
                                                    true
                                                )
                                            }
                                            }
                                            timeInputLabel="Time:"
                                            dateFormat={DATE_TIME_REACT_FORMAT}
                                            showTimeInput
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Phân loại
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Phân loại"
                                            name="category"
                                            value={values.category}
                                            onChange={handleChange}
                                            isInvalid={!!errors.category}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.category}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tác giả
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tác giả"
                                            type="text"
                                            name="author"
                                            value={values.author}
                                            onChange={handleChange}
                                            isInvalid={!!errors.author}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nguồn link
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Đường link nguồn"
                                            name="sourceLink"
                                            value={values.sourceLink}
                                            onChange={handleChange}
                                            isInvalid={!!errors.sourceLink}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.sourceLink}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu điểm
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary1"
                                            type="checkbox"
                                            placeholder="Là tiêu điểm"
                                            name="isFocus"
                                            checked={values.isFocus}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary1">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nổi bật
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary2"
                                            type="checkbox"
                                            placeholder="Nổi bật"
                                            name="isHighlight"
                                            checked={values.isHighlight}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary2">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Lượt view ảo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="virtualViews"
                                            placeholder="Sắp xếp"
                                            value={values.virtualViews}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Đường link nguồn domain
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Đường link nguồn domain"
                                            type="text"
                                            name="baseUrl"
                                            value={values.baseUrl}
                                            onChange={handleChange}
                                            isInvalid={!!errors.baseUrl}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.baseUrl}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group> */}
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Từ nguồn
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Từ nguồn"
                                            name="fromWhichSource"
                                            value={values.fromWhichSource}
                                            onChange={handleChange}
                                            isInvalid={!!errors.fromWhichSource}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.fromWhichSource}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Từ khóa Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Từ khóa"
                                            type="text"
                                            name="keywords"
                                            value={values.keywords}
                                            onChange={handleChange}
                                            isInvalid={!!errors.keywords}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.keywords}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tiêu đề Og"
                                            type="text"
                                            name="ogTitle"
                                            value={values.ogTitle}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogTitle}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogTitle}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Mô tả Og"
                                            type="text"
                                            name="ogDescription"
                                            value={values.ogDescription}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogDescription}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogDescription}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tag Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tag bài báo"
                                            type="text"
                                            name="articleTag"
                                            value={values.articleTag}
                                            onChange={handleChange}
                                            isInvalid={!!errors.articleTag}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.articleTag}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái bài viết
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="postStatus"
                                            id="postStatus"
                                            placeholder="Chọn trạng thái bài viết"
                                            value={values.postStatus}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'postStatus',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            options={objectStatusPost}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                        >
                                            {errors.postStatus}
                                        </div>
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Tạo mới bài viết
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
                <ReactSpinner loadings={isLoadingEdit} />
            </Card.Body>
        </Card>
    );

};

export default NewsCrawlerEdit;
