import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/theme-xcode";
import InputFile from '@app/components/input-file/InputFile';
import { Card, Form, Row, Col, Image } from 'react-bootstrap';
import { acceptImage } from '@app/utils/define';
import { isValidUrl } from '@app/utils/helper';
import { BaseGatewayImage } from '@app/utils/apiUrl';


const DynamicInputEdit = (props) => {
    const { t } = useTranslation();
    const { dataType, handleChangeDynamicData, initValue } = props;
    const [dynamicData, setDynamicData] = useState("");

    // thay đổi giá trị , sau đó cập nhật ngược lại dữ liệu dynamicData trong LandingComponentEdit 
    const onChangeData = (data) => {
        setDynamicData(data);
        handleChangeDynamicData(data);
    }

    // set giá trị ban đầu cho dynamicData truyền dữ liệu từ LandingComponentEdit
    useEffect(() => {
        setDynamicData(initValue ? initValue : '');
    }, [initValue]);

    // thay đổi giá trị ngược lại dữ liệu dynamicData trong LandingComponentEdit  khi upload lại ảnh
    const onUploadImageSuccess = (files) => {
        if (files.length > 0) {
            const imgModel = {
                imagePath: files[0].filePath,
                imageThumbPath: files[0].thumbPath
            }
            onChangeData(JSON.stringify(imgModel));
        }
    }

    switch (dataType) {
        case "html":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.html')}
                    </Form.Label>
                    <Col sm="10">
                        <HtmlInput handleChange={onChangeData} initValue={dynamicData} />
                    </Col>
                </Form.Group>
            )
        case "text":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.text')}
                    </Form.Label>
                    <Col sm="10">
                        <TextInput handleChange={onChangeData} initValue={dynamicData} />
                    </Col>
                </Form.Group>
            )
        case "image": {
            let valueResult = [];
            if (dynamicData) {
                const valueParserJson = JSON.parse(dynamicData);
                if (valueParserJson.imageThumbPath) {
                    valueResult = [isValidUrl(valueParserJson.imageThumbPath) ? valueParserJson.imageThumbPath : BaseGatewayImage + valueParserJson.imageThumbPath]
                } else {
                    valueResult = [isValidUrl(valueParserJson.imagePath) ? valueParserJson.imagePath : BaseGatewayImage + valueParserJson.imagePath]
                }
            }
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.image')}
                    </Form.Label>
                    <Col sm="10">
                        <InputFile name="imageThumbnail"
                            accept={acceptImage}
                            onChange={onUploadImageSuccess}
                            values={valueResult}
                        />
                    </Col>
                </Form.Group>
            )
        }

        case "video":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.video')}
                    </Form.Label>
                    <Col sm="10">
                        <VideoInput handleChange={onChangeData} initValue={dynamicData} />
                    </Col>
                </Form.Group>
            )
        case "model":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.advanced')}
                    </Form.Label>
                    <Col sm="10">
                        <AdvancedInput handleChange={onChangeData} initValue={dynamicData} />
                    </Col>
                </Form.Group>
            )
        default:
            return (<div />)
    };


}
const HtmlInput = (prop) => {
    const { handleChange, initValue } = prop;
    // const [iHtml, setIHtml] = useState(initValue);
    const [iHtml, setIHtml] = useState("");

    const onChange = (newValue) => {
        handleChange(newValue);
        setIHtml(newValue);
    }
    useEffect(() => {
        setIHtml(initValue ? initValue : '');
    }, [initValue]);
    return (
        <AceEditor
            placeholder=""
            mode="c_cpp"
            theme="xcode"
            name="blah2"
            fontSize={14}
            value={iHtml}
            className="code-editor"
            onChange={e => onChange(e)}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
            }} />
    );
}

const TextInput = (prop) => {
    const { handleChange, initValue } = prop;
    return (
        <textarea className="form-control" rows={2} placeholder="" onChange={(e) => handleChange(e.target.value)} value={initValue} />
    );
}

const VideoInput = (prop) => {
    const { handleChange, initValue } = prop;
    return (
        <input
            type="text"
            className="form-control"
            id="idVideoInput"
            placeholder=''
            value={initValue}
            onChange={(e) => handleChange(e.target.value)}
        />
    );
}

const AdvancedInput = (prop) => {
    // initValue khởi tạo giá trị ban đầu , handleChange hàm tác động đến việc thay đổi dữ liệu
    const { handleChange, initValue } = prop;
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('T-1');

    // nội dung của từng tab nâng cao
    const [values, setValues] = useState([{ key: `T-1`, firstText: '', firstTextEn: '', secondText: '', secondTextEn: '', css: '', webLink: '', mediaLink: '', imageThumbTabPath: '', imageTabPath: '' }]);

    // các tab
    const [tabs, setTabs] = useState([{ index: 0, key: 'T-1', text: "Nội dung con 1" }]);

    // nội dung của dữ liệu nâng cao
    const [dataModel, setDataModel] = useState({
        name: '',
        nameEn: '',
        title: '',
        titleEn: '',
        description: '',
        descriptionEn: '',
        imageLink: '',
        lstAdvancedModel: '',
    });

    const [imageThumbPath, setImageThumbPath] = useState("");
    const [imagePath, setImagePath] = useState("");

    // đặt lại giá trị khi thay đổi hình ảnh
    const onUploadImageSuccess = (files) => {
        if (files.length > 0) {
            setImagePath(files[0].filePath);
            setImageThumbPath(files[0].thumbPath);
        }
    }

    // khởi tạo các giá trị ban đầu, nội dung nâng cao, các tab, nội dung các tab
    useEffect(() => {
        const initModel = JSON.parse(initValue);
        setDataModel({ ...dataModel, name: initModel.name, nameEn: initModel.nameEn, title: initModel.title, titleEn: initModel.titleEn, description: initModel.description, descriptionEn: initModel.descriptionEn, imageLink: initModel.imageLink, lstAdvancedModel: JSON.stringify(initModel.lstAdvancedModel) });

        if (initModel && initModel.lstAdvancedModel && initModel.lstAdvancedModel.length > 0) {
            let lstTab = [];
            let lstValue = []
            initModel.lstAdvancedModel.forEach((item, index) => {
                lstTab = [...lstTab, { index: index, key: `T-${index + 1}`, text: `Nội dung con ${index + 1}` }]
                lstValue = [...lstValue, { key: `T-${index + 1}`, firstText: item.firstText, firstTextEn: item.firstTextEn, secondText: item.secondText, secondTextEn: item.secondTextEn, css: item.css, webLink: item.webLink, mediaLink: item.mediaLink, imageThumbTabPath: '', imageTabPath: '' }]

            });
            setTabs(lstTab);
            setValues(lstValue);
        }
    }, [])


    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    // khi dataModel thay đổi thì thay đổi lại nội dung qua handleChange
    useEffect(() => {
        handleChange(JSON.stringify(dataModel))
    }, [dataModel])

    // thêm 1 tab ( cái này chỉ thêm giá trị tab và nội dung khởi tạo rỗng ban đầu của tab mới)
    const handleAddObject = () => {
        let maxIndex = Math.max(...tabs.map(o => o.index));
        ++maxIndex;
        setTabs([...tabs, { index: maxIndex, key: `T- ${maxIndex}`, text: `Nội dung con ${maxIndex + 1}` }]);
        setValues([...values, { key: `T- ${maxIndex}`, firstText: '', firstTextEn: '', secondText: '', secondTextEn: '', css: '', webLink: '', mediaLink: '', imageThumbTabPath: '', imageTabPath: '' }]);
    }
    // xóa 1 tab (  xóa 1 tab , nội dung của tab đấy và cập nhật lại dataModel)
    const handleRemoveTab = (keyTab) => {
        if (tabs && tabs.length > 1) {
            const tabNew = JSON.parse(JSON.stringify(tabs)).filter(x => x.key !== keyTab);
            const valuesNew = JSON.parse(JSON.stringify(values)).filter(x => x.key !== keyTab);
            setActiveTab(tabs[0].key);
            setTabs(tabNew);
            setValues(valuesNew);
            setDataModel({ ...dataModel, lstAdvancedModel: valuesNew.map((element) => { return { firstText: element.firstText, firstTextEn: element.firstTextEn, secondText: element.secondText, secondTextEn: element.secondTextEn, css: element.css, mediaLink: element.mediaLink, webLink: element.webLink } }) });
        }
    }

    const changeValue = (key, property, event) => {
        if (values.some(item => key === item.key)) {
            const valuesTemp = [...values];
            const item = valuesTemp.find((element) => { return element.key === key; });
            switch (property) {
                case 'firstText':
                    item.firstText = event.target.value;
                    break;
                case 'firstTextEn':
                    item.firstTextEn = event.target.value;
                    break;
                case 'secondText':
                    item.secondText = event.target.value;
                    break;
                case 'secondTextEn':
                    item.secondTextEn = event.target.value;
                    break;
                case 'css':
                    item.css = event.target.value;
                    break;
                case 'webLink':
                    item.webLink = event.target.value;
                    break;
                case 'mediaLink':
                    item.mediaLink = event.target.value;
                    break;
                case 'image':
                    if (event[0] && event[0].filePath && event[0].thumbPath) {
                        item.imageTabPath = event[0].filePath;
                        item.imageThumbTabPath = event[0].thumbPath;
                    }
                    break;
                default:
                    break;
            }
            setValues(valuesTemp);
            setDataModel({ ...dataModel, lstAdvancedModel: valuesTemp.map((element) => { return { firstText: element.firstText, firstTextEn: element.firstTextEn, secondText: element.secondText, secondTextEn: element.secondTextEn, css: element.css, mediaLink: element.mediaLink, webLink: element.webLink } }) });
        }
    }

    const handleChangeText = (event, property) => {
        switch (property) {
            case 'name':
                setDataModel({ ...dataModel, name: event.target.value });
                break;
            case 'nameEn':
                setDataModel({ ...dataModel, nameEn: event.target.value });
                break;
            case 'title':
                setDataModel({ ...dataModel, title: event.target.value });
                break;
            case 'titleEn':
                setDataModel({ ...dataModel, titleEn: event.target.value });
                break;
            case 'description':
                setDataModel({ ...dataModel, description: event.target.value });
                break;
            case 'descriptionEn':
                setDataModel({ ...dataModel, descriptionEn: event.target.value });
                break;
            case 'imageLink':
                setDataModel({ ...dataModel, imageLink: event.target.value });
                break;
            default:
                break;
        }
    }

    return (
        <Card>
            <Card.Header as="h5"> {t('landing.label.advanced')}</Card.Header>
            <Card.Body>
                <Form noValidate>
                    <Row>
                        <Col xs={6}>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputName" className="col-sm-3 col-form-label">{t('landing.label.name')}</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="idInputName" placeholder='' onChange={e => handleChangeText(e, 'name')} value={dataModel.name} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputName" style={{ color: 'red' }} className="col-sm-3 col-form-label">Tên hiển thị(Tiếng Anh)</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="idInputName" placeholder='' onChange={e => handleChangeText(e, 'nameEn')} value={dataModel.nameEn} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputTitle" className="col-sm-3 col-form-label">{t('landing.label.title')}</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="idInputTitle" onChange={e => handleChangeText(e, 'title')} value={dataModel.title} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputTitle" style={{ color: 'red' }} className="col-sm-3 col-form-label">Tiêu đề hiển thị (Tiếng Anh)</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="idInputTitle" onChange={e => handleChangeText(e, 'titleEn')} value={dataModel.titleEn} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputTitle" className="col-sm-3 col-form-label">{t('landing.label.description')}</label>
                                <div className="col-sm-9">
                                    <textarea className="form-control" rows={2} onChange={e => handleChangeText(e, 'description')} value={dataModel.description} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputTitle" style={{ color: 'red' }} className="col-sm-3 col-form-label">Mô tả (Tiếng Anh)</label>
                                <div className="col-sm-9">
                                    <textarea className="form-control" rows={2} onChange={e => handleChangeText(e, 'descriptionEn')} value={dataModel.descriptionEn} />
                                </div>
                            </Form.Group>
                            {/* <Form.Group as={Row} className="mb-3">
                        <Form.Label className="col-sm-2 col-form-label">
                            Lấy đường dẫn ảnh
                        </Form.Label>
                        <Col sm="10">
                            <InputFile name="imageThumbnail"
                                accept={acceptImage}
                                values={[isValidUrl(dataModel.imageLink) ? dataModel.imageLink : `${BaseGatewayImage}${dataModel.imageLink}`]}
                                onChange={onUploadImageSuccess}
                            />
                            <div
                                style={{
                                    display: 'block',
                                    color: '#000000'
                                }}
                                className="invalid-feedback"
                            >
                                {imageThumbPath ? imageThumbPath : ""}
                            </div>
                            <div
                                style={{
                                    display: 'block',
                                    color: '#000000'
                                }}
                                className="invalid-feedback"
                            >
                                {imagePath ? imagePath : ""}
                            </div>
                        </Col>
                    </Form.Group> */}
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputImageLink" className="col-sm-3 col-form-label">Ảnh hiển thị</label>
                                <div className="col-sm-9">
                                    {
                                        dataModel.imageLink ?
                                            <div style={{ width: '200px', height: '200px' }}>
                                                <Image

                                                    src={
                                                        isValidUrl(dataModel.imageLink) ? dataModel.imageLink : BaseGatewayImage + dataModel.imageLink
                                                    }
                                                    thumbnail
                                                />
                                            </div>
                                            : null
                                    }
                                    <input className="form-control" placeholder='Đường dẫn ảnh' onChange={e => handleChangeText(e, 'imageLink')} value={dataModel.imageLink} />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-4 col-form-label">
                                    { }
                                </Form.Label>
                                <Col sm="8">
                                    <InputFile name="imageThumbnail"
                                        labelImage="Upload ảnh để lấy link"
                                        accept={acceptImage}
                                        onChange={onUploadImageSuccess}
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#000000'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {imageThumbPath}
                                    </div>
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#000000'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {imagePath}
                                    </div>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Form.Group as={Row} className="form-group row">
                        <div className="card card-primary card-outline card-outline-tabs col-md-12">
                            <div className="card-header p-0 border-bottom-0">
                                <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                    {tabs.map((tab) => (
                                        <li style={{ position: 'relative' }} className="nav-item" key={tab.key}>
                                            <i onClick={() => handleRemoveTab(tab.key)} onKeyDown={() => { }} style={{ position: 'absolute', top: '-16px', right: '-5px', cursor: 'pointer' }} className="far fa-times-circle">{ }</i>
                                            <a onClick={() => toggle(tab.key)} className={`nav-link ${activeTab === tab.key ? 'active' : ''}`} id="custom-tabs-first-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-first" aria-selected="true">{tab.text}</a>
                                        </li>

                                    ))}
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="custom-tabs-four-tabContent">
                                    {tabs.map((tab) => (
                                        <TabContent isActive={activeTab === tab.key} ikey={tab.key} handleChange={changeValue} key={tab.key} value={values.find(x => x.key === tab.key)} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </Card.Body>
            <Card.Footer>
                <button type="button" className="btn btn-info float-right" onClick={() => handleAddObject()}>{t('landing.button.addObject')} </button>
            </Card.Footer>
        </Card>
    );
}

const TabContent = (props) => {
    const { isActive, ikey, handleChange, value } = props;
    const { t } = useTranslation();
    const [lstImage, setLstIamge] = useState([]);

    useEffect(() => {
        if (value.mediaLink) {
            setLstIamge([isValidUrl(value.mediaLink) ? value.mediaLink : `${BaseGatewayImage}${value.mediaLink}`]);
        }
    }, [value.mediaLink])
    return (
        <div className={`tab-pane fade ${isActive ? 'show active' : ''}`} id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
            <Form noValidate>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Nội sung số 1</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "firstText", e)} value={value.firstText} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" style={{ color: 'red' }} className="col-sm-2 col-form-label">Nội sung số 1 (Tiếng Anh)</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "firstTextEn", e)} value={value.firstTextEn} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Nội dung số 2</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "secondText", e)} value={value.secondText} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" style={{ color: 'red' }} className="col-sm-2 col-form-label">Nội dung số 2 (Tiếng Anh)</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "secondTextEn", e)} value={value.secondTextEn} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Các thẻ Css</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "css", e)} value={value.css} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Nguồn link</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "webLink", e)} value={value.webLink} />
                    </div>
                </Form.Group>
                {/* <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        Lấy đường dẫn ảnh
                    </Form.Label>
                    <Col sm="10">
                        <InputFile name="imageThumbnail"
                            accept={acceptImage}
                            values={lstImage}
                            onChange={(e) => handleChange(ikey, "image", e)}
                        />
                        <div
                            style={{
                                display: 'block',
                                color: '#000000'
                            }}
                            className="invalid-feedback"
                        >
                            {value.imageThumbTabPath}
                        </div>
                        <div
                            style={{
                                display: 'block',
                                color: '#000000'
                            }}
                            className="invalid-feedback"
                        >
                            {value.imageTabPath}
                        </div>
                    </Col>
                </Form.Group> */}
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Ảnh hiển thị</label>
                    <div className="col-sm-10">
                        {
                            value.mediaLink ?
                                <div style={{ width: '200px', height: '200px' }}>
                                    <Image
                                        src={
                                            isValidUrl(value.mediaLink) ? value.mediaLink : BaseGatewayImage + value.mediaLink
                                        }
                                        thumbnail
                                    />
                                </div>
                                : null
                        }
                        <input type="text" className="form-control" id="idInputName2" placeholder='Đường dẫn ảnh' onChange={(e) => handleChange(ikey, "mediaLink", e)} value={value.mediaLink} />
                    </div>
                </Form.Group>
            </Form>
        </div>
    );
}

export default DynamicInputEdit;
