import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ACCOUNT } from '../constants';
import { Get, Post, Put, DeleteSingle } from '../../utils/httpProvider';
import { ACCOUNT_API, ACCOUNT_DETAIL_API, ACCOUNT_PROJECT_API } from '../../utils/apiUrl';

export const detailAccountPagingAction = createAsyncThunk(
    ACCOUNT.DETAIL_ACCOUNT,
    async (id) => {
        return Get(ACCOUNT_DETAIL_API.DETAIL_ACCOUNT, id, null)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

export const createAccountDetailAction = createAsyncThunk(
    ACCOUNT.CREATE_DETAIL_ACCOUNT,
    async (model, { rejectWithValue, fulfillWithValue }) => {
        return Post(ACCOUNT_DETAIL_API.CREATE_DETAIL_ACCOUNT, null, model.data)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);

export const accountPagingAction = createAsyncThunk(
    ACCOUNT.GET_PAGING,
    async (model) => {
        return Get(ACCOUNT_API.GET_PAGING_ACCOUNT, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

export const addAccountAction = createAsyncThunk(
    ACCOUNT.ADD_ACCOUNT,
    async (model, { rejectWithValue, fulfillWithValue }) => {
        return Post(ACCOUNT_API.ADD_ACCOUNT, null, model, false)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);

export const getByIdAccountAction = createAsyncThunk(
    ACCOUNT.GET_BY_ID_ACCOUNT,
    async (id) => {
        return Get(ACCOUNT_API.GET_BY_ID_ACCOUNT, id, null)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

export const updateAccountAction = createAsyncThunk(
    ACCOUNT.UPDATE_ACCOUNT_CONST,
    async (model, { rejectWithValue, fulfillWithValue }) => {
        return Put(ACCOUNT_API.EDIT_ACCOUNT, model.id, model.data)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);

export const deleteAccountAction = createAsyncThunk(
    ACCOUNT.DELETE_ACCOUNT_CONST,
    async (id, { rejectWithValue, fulfillWithValue }) => {
        return DeleteSingle(ACCOUNT_API.DELETE_ACCOUNT, id, null)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);

export const addAccountProjectAction = createAsyncThunk(
    ACCOUNT.CREATE_PROJECT_ACCOUNT,
    async (model) => {
        console.log('model', model);
        return Post(ACCOUNT_PROJECT_API.CREATE_ITEM, null, model)
            .then(async (response) => {
                if (response.status === 200) {

                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);

export const getByIdAccountProjectAction = createAsyncThunk(
    ACCOUNT.GET_BY_ID_PROJECT,
    async (id) => {
        return Get(ACCOUNT_PROJECT_API.GET_ITEM, id, null)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

export const getPagingAccountProjectAction = createAsyncThunk(
    ACCOUNT.GET_PAGING_PROJECT,
    async (model) => {
        return Get(ACCOUNT_PROJECT_API.GET_PAGING, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

export const deleteAccountProjectAction = createAsyncThunk(
    ACCOUNT.DELETE_PROJECT_ACCOUNT,
    async (id, { rejectWithValue, fulfillWithValue }) => {
        return DeleteSingle(ACCOUNT_PROJECT_API.DELETE_ITEM, id, null)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);

export const updateAccountProjectAction = createAsyncThunk(
    ACCOUNT.UPDATE_PROJECT_ACCOUNT,
    async (model, { rejectWithValue, fulfillWithValue }) => {
        return Put(ACCOUNT_PROJECT_API.UPDATE_ITEM, model.id, model.data)
            .then(async (response) => {
                if (response.status === 200) {
                    return fulfillWithValue(response.status);
                }
                return rejectWithValue(await response.json());
            })
            .catch((error) => error);
    }
);
const initialState = {
    listAccount: [],
    detailAccount: [],
    totalAccount: 0,
    itemAccount: null,
    projectAccount: null,
    listProjectAccount: [],
};

export const accountSlice = createSlice({
    name: ACCOUNT.NAME_SLICE,
    initialState,
    reducers: {
        // filter lúc xóa bản ghi thành công
        filterListAccount: (state, action) => {
            state.listAccount =
                state.listAccount.length > 0
                    ? state.listAccount.filter((x) => x.id !== action.payload)
                    : [];
            state.totalAccount -= 1;
        },
        // cập nhật lại thông tin tài khoản trong local khi update
        reloadAccount: (state, action) => {
            const itemAccountNews = { ...state.itemAccount };
            itemAccountNews.fullName = action.payload.fullname;
            itemAccountNews.email = action.payload.email;
            state.itemAccount = itemAccountNews;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(accountPagingAction.fulfilled, (state, action) => {
                if (action.payload && action.payload.data && action.payload.totalRecords) {
                    state.listAccount = action.payload.data
                        ? action.payload.data.$values
                        : [];
                    state.totalAccount = action.payload.totalRecords;
                }
            })
            .addCase(detailAccountPagingAction.fulfilled, (state, action) => {
                if (action.payload) {
                    state.detailAccount = action.payload;
                } 
                else {
                    state.detailAccount = [];
                }
            })
            .addCase(getByIdAccountProjectAction.fulfilled, (state, action) => {
                if (action.payload) {
                    state.projectAccount = action.payload;
                }
            })
            .addCase(getPagingAccountProjectAction.fulfilled, (state, action) => {
                if (action.payload && action.payload.data && action.payload.data.$values) {
                    state.listProjectAccount = action.payload.data.$values;
                }
            })
            .addCase(getByIdAccountAction.fulfilled, (state, action) => {
                if (action.payload) {
                    state.itemAccount = action.payload;
                }
            })
            .addCase(getByIdAccountAction.rejected, (state) => {
                state.itemAccount = null;
            })
            .addCase(updateAccountProjectAction.fulfilled, (state, action) => {
                if (action.payload) {
                    state.projectAccount = action.payload;
                }
            })
            .addCase(updateAccountProjectAction.rejected, (state, action) => {
                console.error("Update failed: ", action.payload)
            })
    }
});

export const { filterListAccount, reloadAccount } = accountSlice.actions;

export default accountSlice.reducer;
