import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import {useDispatch} from 'react-redux';
// import {Gatekeeper} from 'gatekeeper-client-sdk';
// import {loadUser, logoutUser} from '@store/reducers/auth';
import Dashboard from '@pages/Dashboard';
import Profile from '@pages/profile/Profile';
import Account from '@pages/account/Account';
import AccountAdd from '@pages/account/AccountAdd';
import AccountEdit from '@pages/account/AccountEdit';
import AccountDetail from '@app/pages/account/AccountDetail';
import LandingComponent from '@pages/landing/LandingComponent';
import LandingComponentAdd from '@pages/landing/LandingComponentAdd';
import LandingComponentEdit from '@pages/landing/LandingComponentEdit';
import NewsCrawler from '@app/pages/newsCrawler/NewsCrawler';
import NewsCrawlerEdit from '@app/pages/newsCrawler/NewsCrawlerEdit';
import SlideImage from '@pages/slide-image/SlideImage';
import SlideImageAdd from '@pages/slide-image/SlideImageAdd';
import SlideImageEdit from '@pages/slide-image/SlideImageEdit';
import EmailConfig from '@pages/emailConfig/EmailConfig';
import EmailConfigAdd from '@pages/emailConfig/EmailConfigAdd';
import EmailConfigEdit from '@pages/emailConfig/EmailConfigEdit';
import Partner from '@pages/partner/Partner';
import PartnerAdd from '@pages/partner/PartnerAdd';
import PartnerEdit from '@pages/partner/PartnerEdit';
import Category from '@pages/category/Category';
import CategoryAdd from '@pages/category/CategoryAdd';
import CategoryEdit from '@pages/category/CategoryEdit';
import Post from '@app/pages/post/Posts';
import PostAdd from '@pages/post/PostAdd';
import PostEdit from '@pages/post/PostEdit';

import Banner from '@pages/banner/Banner';

import Log from '@pages/log/Log';

import LoginHistory from '@app/pages/report/countLogin/LoginHistory';
import StatisticLoginHistory from '@app/pages/report/countLogin/StatisticLoginHistory';

import Video from '@pages/video/Video';
import VideoAdd from '@pages/video/VideoAdd';
import VideoEdit from '@pages/video/VideoEdit';

import Html5Banner from '@pages/html5Banner/Html5Banner';
import Html5BannerAdd from '@pages/html5Banner/Html5BannerAdd';
import Html5BannerEdit from '@pages/html5Banner/Html5BannerEdit';

import ReportPostByWebAnalysis from '@pages/report/ReportPostByWebAnalysis';
import ReportPostQuantityCreateBy from '@app/pages/report/ReportPostQuantityCreateBy';
import ReportPostCreateBy from '@app/pages/report/ReportPostCreateBy';
import ReportViewDetail from '@app/pages/report/reportViewDetail/ReportViewDetail';
import ReportPostPublishTimeAndDataType from '@pages/report/ReportPostPublishTimeAndDataType';
import RepostAdvertising from '@pages/report/RepostAdvertising';
import LibraryImage from '@pages/library-image/LibraryImage';
import LibraryImageAdd from '@pages/library-image/LibraryImageAdd';
import LibraryImageEdit from '@pages/library-image/LibraryImageEdit';
import Contact from '@pages/contact/Contact';
import Giaodien from '@pages/giaodien/Giaodien';
import AddGiaodien from '@pages/giaodien/GiaodienAdd';
import GiaodienEdit from '@pages/giaodien/GiaodienEdit';

import Introduce from '@pages/introduce/Introduce';
import IntroduceAdd from '@pages/introduce/IntroduceAdd';
import IntroduceEdit from '@pages/introduce/IntroduceEdit';

import Person from '@pages/person/Person';
import PersonAdd from '@pages/person/PersonAdd';
import PersonEdit from '@pages/person/PersonEdit';

import Header from './header/Header';
import Footer from './footer/Footer';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import ReportPost from '../../pages/report/ReportPost';
import ReportPostSynthetic from '../../pages/report/ReportPostSynthetic';
import { URI_INTERNAL } from '../../utils/pathLocations';
import AccountProjectAdd from '@app/pages/account/AccountProjectAdd';
import AccountProjectEdit from '@app/pages/account/AccountProjectEdit';
// import PageLoading from '../../components/page-loading/PageLoading';

const Main = () => {
    // const [appLoadingState, updateAppLoading] = useState(false);
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     updateAppLoading(true);
    //     const fetchProfile = async () => {
    //         try {
    //             const response = await Gatekeeper.getProfile();
    //             dispatch(loadUser(response));
    //             updateAppLoading(false);
    //         } catch (error) {
    //             dispatch(logoutUser());
    //             updateAppLoading(false);
    //         }
    //     };
    //     fetchProfile();
    //     return () => {};
    // }, []);

    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');

    document.body.className += ' sidebar-mini';

    const toggleMenuSidebar = () => {
        document.body.classList.add('sidebar-collapse');
        document.body.classList.remove('sidebar-open');
    };

    const template = (
        <>
            <Header />

            <MenuSidebar />

            <div className="content-wrapper">
                <div className="pt-3" />
                <section className="content">
                    <Switch>
                        <Route exact path="/profile" component={Profile} />
                        <Route exact path="/" component={Dashboard} />
                        <Route
                            exact
                            path={URI_INTERNAL.PERSON_PATH}
                            component={Person}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.PERSON_ADD_PATH}
                            component={PersonAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.PERSON_EDIT_PATH}
                            component={PersonEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.ACCOUNT_PATH}
                            component={Account}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.ACCOUNT_PATH_ADD}
                            component={AccountAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.ACCOUNT_PATH_EDIT}
                            component={AccountEdit}
                        />
                         <Route
                            exact
                            path={URI_INTERNAL.ACCOUNT_PATH_DETAIL}
                            component={AccountDetail}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.ACCOUNT_PROJECT_PATH_ADD}
                            component={AccountProjectAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.ACCOUNT_PROJECT_PATH_EDIT}
                            component={AccountProjectEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.LANDING_COMPONENT_PATH}
                            component={LandingComponent}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.LANDING_COMPONENT_CREATE_PATH}
                            component={LandingComponentAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.LANDING_COMPONENT_EDIT_PATH}
                            component={LandingComponentEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.NEWSCRAWLER_PATH}
                            component={NewsCrawler}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.NEWSCRAWLER_EDIT_PATH}
                            component={NewsCrawlerEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.SLIDE_PATH}
                            component={SlideImage}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.SLIDE_CREATE_PATH}
                            component={SlideImageAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.SLIDE_EDIT_PATH}
                            component={SlideImageEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.BANNER_PATH}
                            component={Banner}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.CONTACT_PATH}
                            component={Contact}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.GIAODIEN_PATH}
                            component={Giaodien}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.GIAODIEN_ADD_PATH}
                            component={AddGiaodien}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.GIAODIEN_EDIT_PATH}
                            component={GiaodienEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.EMAILCONFIG_PATH}
                            component={EmailConfig}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.EMAILCONFIG_CREATE_PATH}
                            component={EmailConfigAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.EMAILCONFIG_EDIT_PATH}
                            component={EmailConfigEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.PARTNER_PATH}
                            component={Partner}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.PARTNER_CREATE_PATH}
                            component={PartnerAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.PARTNER_EDIT_PATH}
                            component={PartnerEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.INTRODUCE_PATH}
                            component={Introduce}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.INTRODUCE_ADD_PATH}
                            component={IntroduceAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.INTRODUCE_EDIT_PATH}
                            component={IntroduceEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.CATEGORY_PATH}
                            component={Category}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.CATEGORY_CREATE_PATH}
                            component={CategoryAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.CATEGORY_EDIT_PATH}
                            component={CategoryEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.LOGIN_HISTORY_PATH}
                            component={LoginHistory}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.STATISTIC_HISTORY_PATH}
                            component={StatisticLoginHistory}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.POST_PATH}
                            component={Post}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.POST_CREATE_PATH}
                            component={PostAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.POST_EDIT_PATH}
                            component={PostEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.LOG_PATH}
                            component={Log}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.VIDEO_PATH}
                            component={Video}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.VIDEO_CREATE_PATH}
                            component={VideoAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.VIDEO_EDIT_PATH}
                            component={VideoEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_PATH}
                            component={ReportPost}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_SYNTHETIC_PATH}
                            component={ReportPostSynthetic}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_WEBANALYSIS_PATH}
                            component={ReportPostByWebAnalysis}
                        />
                        <Route
                            exact
                            path={
                                URI_INTERNAL.REPORT_POST_EXCEL_QUANTITY_CREATE_BY
                            }
                            component={ReportPostQuantityCreateBy}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_EXCEL_CREATE_BY}
                            component={ReportPostCreateBy}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.REPORT_POST_VIEW_DETAIL}
                            component={ReportViewDetail}
                        />
                        <Route
                            exact
                            path={
                                URI_INTERNAL.REPORT_POST_BY_DATETYPE
                            }
                            component={ReportPostPublishTimeAndDataType}
                        />
                        <Route
                            exact
                            path={
                                URI_INTERNAL.REPORT_ADVERTISING_ANALYSIS_PATH
                            }
                            component={RepostAdvertising}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.HTML5BANNER_PATH}
                            component={Html5Banner}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.HTML5BANNER_CREATE_PATH}
                            component={Html5BannerAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.HTML5BANNER_EDIT_PATH}
                            component={Html5BannerEdit}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.LIBRARY_IMAGE_PATH}
                            component={LibraryImage}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.LIBRARY_IMAGE_CREATE_PATH}
                            component={LibraryImageAdd}
                        />
                        <Route
                            exact
                            path={URI_INTERNAL.LIBRARY_IMAGE_EDIT_PATH}
                            component={LibraryImageEdit}
                        />
                    </Switch>
                </section>
            </div>
            <Footer />
            <div
                id="sidebar-overlay"
                role="presentation"
                onClick={toggleMenuSidebar}
                onKeyDown={() => { }}
            />
        </>
    );

    // if (appLoadingState) {
    //     template = <PageLoading />;
    // } else {
    //     template = (
    //         <>
    //             <Header toggleMenuSidebar={toggleMenuSidebar} />

    //             <MenuSidebar />

    //             <div className="content-wrapper">
    //                 <div className="pt-3" />
    //                 <section className="content">
    //                     <Switch>
    //                         <Route exact path="/profile" component={Profile} />
    //                         <Route exact path="/" component={Dashboard} />
    //                     </Switch>
    //                 </section>
    //             </div>
    //             <Footer />
    //             <div
    //                 id="sidebar-overlay"
    //                 role="presentation"
    //                 onClick={toggleMenuSidebar}
    //                 onKeyDown={() => {}}
    //             />
    //         </>
    //     );
    // }

    return <div className="wrapper">{template}</div>;
};

export default Main;
