import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/theme-xcode";
import InputFile from '@app/components/input-file/InputFile';
import { Card, Form, Row, Col, Image } from 'react-bootstrap';
import { acceptImage } from '@app/utils/define';
import { isValidUrl } from '@app/utils/helper';
import { BaseGatewayImage } from '@app/utils/apiUrl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const DynamicInput = (props) => {
    const { t } = useTranslation();
    const { dataType, handleChangeDynamicData } = props;
    const [imageThumbPath, setImageThumbPath] = useState("");
    const [imagePath, setImagePath] = useState("");

    const onChangeData = (data) => {
        handleChangeDynamicData(data);
    }

    // hàm upload ảnh
    const onUploadImageSuccess = (files) => {
        if (files.length > 0) {
            const imgModel = {
                imagePath: files[0].filePath,
                imageThumbPath: files[0].thumbPath
            }
            setImagePath(files[0].filePath);
            setImageThumbPath(files[0].thumbPath);
            onChangeData(JSON.stringify(imgModel));
        }
    }

    switch (dataType) {
        case "html":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.html')}
                    </Form.Label>
                    <Col sm="10">
                        <HtmlInput handleChange={onChangeData} />
                    </Col>
                </Form.Group>
            )
        case "text":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.text')}
                    </Form.Label>
                    <Col sm="10">
                        <TextInput handleChange={onChangeData} />
                    </Col>
                </Form.Group>
            )
        case "image":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.image')}
                    </Form.Label>
                    <Col sm="10">
                        <InputFile name="imageThumbnail"
                            accept={acceptImage}
                            onChange={onUploadImageSuccess}
                        />
                        <div
                            style={{
                                display: 'block',
                                color: '#000000'
                            }}
                            className="invalid-feedback"
                        >
                            {imageThumbPath}
                        </div>
                        <div
                            style={{
                                display: 'block',
                                color: '#000000'
                            }}
                            className="invalid-feedback"
                        >
                            {imagePath}
                        </div>
                    </Col>
                </Form.Group>
            )
        case "video":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.video')}
                    </Form.Label>
                    <Col sm="10">
                        <VideoInput handleChange={onChangeData} />
                    </Col>
                </Form.Group>
            )
        case "model":
            return (
                <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                        {t('landing.label.advanced')}
                    </Form.Label>
                    <Col sm="10">
                        <AdvancedInput handleChange={onChangeData} />
                    </Col>
                </Form.Group>
            )
        default:
            return (<div />)
    };


}
// hiển thị kiểu dữ liệu Html
const HtmlInput = (prop) => {
    const [iHtml, setIHtml] = useState('');
    const { handleChange } = prop;
    const onChange = (newValue) => {
        handleChange(newValue);
        setIHtml(newValue);
    }

    return (
        <AceEditor
            placeholder=""
            mode="c_cpp"
            theme="xcode"
            name="blah2"
            fontSize={14}
            value={iHtml}
            className="code-editor"
            onChange={e => onChange(e)}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
            }} />
    );
}

// Hiển thị kiểu text
const TextInput = (prop) => {
    const { handleChange } = prop;
    return (
        <textarea className="form-control" rows={2} placeholder="Enter ..." onChange={(e) => handleChange(e.target.value)} />
    );
}

// thằng này không dùng trong image
const ImageInput = (prop) => {
    return (<InputFile multiple={false} onChange={() => { }} />);
}


// Hiển thị Video
const VideoInput = (prop) => {
    const { handleChange } = prop;
    return (
        <input
            type="text"
            className="form-control"
            id="idVideoInput"
            placeholder=''
            onChange={(e) => handleChange(e.target.value)}
        />
    );
}

// Hiển thị dữ liệu nâng cao
const AdvancedInput = (prop) => {
    const { handleChange } = prop;
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('T-1');
    const [values, setValues] = useState([{ key: `T-1`, firstText: '', firstTextEn: '', secondText: '', secondTextEn: '', css: '', webLink: '', mediaLink: '', imageThumbTabPath: '', imageTabPath: '' }]);
    const [tabs, setTabs] = useState([{ index: 0, key: 'T-1', text: "Nội dung con 1" }]);
    const [dataModel, setDataModel] = useState({
        name: '',
        nameEn: '',
        title: '',
        titleEn: '',
        description: '',
        descriptionEn: '',
        imageLink: '',
        lstAdvancedModel: '',
    });
    const [imageThumbPath, setImageThumbPath] = useState("");
    const [imagePath, setImagePath] = useState("");


    const onUploadImageSuccess = (files) => {
        if (files.length > 0) {
            const imgModel = {
                imagePath: files[0].filePath,
                imageThumbPath: files[0].thumbPath
            }
            setImagePath(files[0].filePath);
            setImageThumbPath(files[0].thumbPath);
        }
    }

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        handleChange(JSON.stringify(dataModel))
    }, [dataModel])

    // hàm thêm các đối tượng nâng cao
    const handleAddObject = () => {
        let maxIndex = Math.max(...tabs.map(o => o.index));
        ++maxIndex;
        setTabs([...tabs, { index: maxIndex, key: `T- ${maxIndex}`, text: `Nội dung con ${maxIndex + 1}` }]);
        setValues([...values, { key: `T- ${maxIndex}`, firstText: '', firstTextEn: '', secondText: '', secondTextEn: '', css: '', webLink: '', mediaLink: '', imageThumbTabPath: '', imageTabPath: '' }]);
    }

    const handleRemoveTab = (keyTab) => {
        if (tabs && tabs.length > 1) {
            const tabNew = JSON.parse(JSON.stringify(tabs)).filter(x => x.key !== keyTab);
            const valuesNew = JSON.parse(JSON.stringify(values)).filter(x => x.key !== keyTab);
            setActiveTab(tabs[0].key);
            setTabs(tabNew);
            setValues(valuesNew);
            setDataModel({ ...dataModel, lstAdvancedModel: valuesNew.map((element) => { return { firstText: element.firstText, firstTextEn: element.firstTextEn, secondText: element.secondText, secondTextEn: element.secondTextEn, css: element.css, mediaLink: element.mediaLink, webLink: element.webLink } }) });
        }
    }

    // hàm cập nhật dữ liệu trong tab của dữ liệu nâng cao
    const changeValue = (key, property, event) => {
        if (values.some(item => key === item.key)) {
            const valuesTemp = [...values];
            const item = valuesTemp.find((element) => { return element.key === key; });
            switch (property) {
                case 'firstText':
                    item.firstText = event.target.value;
                    break;
                case 'firstTextEn':
                    item.firstTextEn = event.target.value;
                    break;
                case 'secondText':
                    item.secondText = event.target.value;
                    break;
                case 'secondTextEn':
                    item.secondTextEn = event.target.value;
                    break;
                case 'css':
                    item.css = event.target.value;
                    break;
                case 'webLink':
                    item.webLink = event.target.value;
                    break;
                case 'mediaLink':
                    item.mediaLink = event.target.value;
                    break;
                case 'image':
                    if (event[0] && event[0].filePath && event[0].thumbPath) {
                        item.imageTabPath = event[0].filePath;
                        item.imageThumbTabPath = event[0].thumbPath;
                    }
                    break;
                default:
                    break;
            }
            setValues(valuesTemp);
            setDataModel({ ...dataModel, lstAdvancedModel: valuesTemp.map((element) => { return { firstText: element.firstText, firstTextEn: element.firstTextEn, secondText: element.secondText, secondTextEn: element.secondTextEn, css: element.css, mediaLink: element.mediaLink, webLink: element.webLink } }) });
        }
    }

    // hàm cập nhật dữ liệu trong dữ liệu nâng cao
    const handleChangeText = (event, property) => {
        switch (property) {
            case 'name':
                setDataModel({ ...dataModel, name: event.target.value });
                break;
            case 'nameEn':
                setDataModel({ ...dataModel, nameEn: event.target.value });
                break;
            case 'title':
                setDataModel({ ...dataModel, title: event.target.value });
                break;
            case 'titleEn':
                setDataModel({ ...dataModel, titleEn: event.target.value });
                break;
            case 'description':
                setDataModel({ ...dataModel, description: event.target.value });
                break;
            case 'descriptionEn':
                setDataModel({ ...dataModel, descriptionEn: event.target.value });
                break;
            case 'imageLink':
                setDataModel({ ...dataModel, imageLink: event.target.value });
                break;
            default:
                break;
        }
    }

    // nội dung dữ liệu nâng cao
    return (
        <Card>
            <Card.Header as="h5"> {t('landing.label.advanced')}</Card.Header>
            <Card.Body>

                <Form noValidate>
                    <Row>
                        <Col xs={6}>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputName" className="col-sm-3 col-form-label">{t('landing.label.name')}</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="idInputName" placeholder='' onChange={e => handleChangeText(e, 'name')} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputName" style={{color:'red'}} className="col-sm-3 col-form-label">Tên hiển thị (Tiếng Anh)</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="idInputName" placeholder='' onChange={e => handleChangeText(e, 'nameEn')} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputTitle" className="col-sm-3 col-form-label">{t('landing.label.title')}</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="idInputTitle" onChange={e => handleChangeText(e, 'title')} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputTitle" style={{color:'red'}}  className="col-sm-3 col-form-label">Tiêu đề hiển thị (Tiếng Anh)</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="idInputTitle" onChange={e => handleChangeText(e, 'titleEn')} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputTitle" className="col-sm-3 col-form-label">{t('landing.label.description')}</label>
                                <div className="col-sm-9">
                                    <textarea className="form-control" rows={2} onChange={e => handleChangeText(e, 'description')} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputTitle" style={{color:'red'}}  className="col-sm-3 col-form-label">Mô tả (Tiếng Anh)</label>
                                <div className="col-sm-9">
                                    <textarea className="form-control" rows={2} onChange={e => handleChangeText(e, 'descriptionEn')} />
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="form-group row">
                                <label htmlFor="inputImageLink" className="col-sm-3 col-form-label">Ảnh hiển thị</label>
                                <div className="col-sm-9">
                                    {
                                        dataModel.imageLink ?
                                            <div style={{ width: '200px', height: '200px' }}>
                                                <Image

                                                    src={
                                                        isValidUrl(dataModel.imageLink) ? dataModel.imageLink : BaseGatewayImage + dataModel.imageLink
                                                    }
                                                    thumbnail
                                                />
                                            </div>
                                            : null
                                    }
                                    <input className="form-control" placeholder='Đường dẫn ảnh' onChange={e => handleChangeText(e, 'imageLink')} />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="col-sm-4 col-form-label">
                                    { }
                                </Form.Label>
                                <Col sm="8">
                                    <InputFile name="imageThumbnail"
                                        labelImage="Upload ảnh để lấy link"
                                        accept={acceptImage}
                                        onChange={onUploadImageSuccess}
                                    />
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#000000'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {imageThumbPath}
                                    </div>
                                    <div
                                        style={{
                                            display: 'block',
                                            color: '#000000'
                                        }}
                                        className="invalid-feedback"
                                    >
                                        {imagePath}
                                    </div>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Form.Group as={Row} className="form-group row">
                        <div className="card card-primary card-outline card-outline-tabs col-md-12">
                            <div className="card-header p-0 border-bottom-0">
                                <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                    {tabs.map((tab) => (
                                        <li style={{ position: 'relative' }} className="nav-item" key={tab.key}>
                                            <i onClick={() => handleRemoveTab(tab.key)} onKeyDown={() => { }} style={{ position: 'absolute', top: '-16px', right: '-5px', cursor: 'pointer' }} className="far fa-times-circle">{ }</i>
                                            <a onClick={() => toggle(tab.key)} className={`nav-link ${activeTab === tab.key ? 'active' : ''}`} id="custom-tabs-first-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-first" aria-selected="true">{tab.text}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="custom-tabs-four-tabContent">
                                    {tabs.map((tab) => (
                                        <TabContent isActive={activeTab === tab.key} ikey={tab.key} handleChange={changeValue} key={tab.key} value={values.find(x => x.key === tab.key)} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </Card.Body>
            <Card.Footer>
                <button type="button" className="btn btn-info float-right" onClick={() => handleAddObject()}>{t('landing.button.addObject')} </button>
            </Card.Footer>
        </Card>
    );
}

// Nội dung các tab content của dữ liệu nâng cao
const TabContent = (props) => {
    const { isActive, ikey, handleChange, value } = props;
    const { t } = useTranslation();
    return (
        <div className={`tab-pane fade ${isActive ? 'show active' : ''}`} id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
            <Form noValidate>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Nội dung số 1</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName1" placeholder='' onChange={(e) => handleChange(ikey, "firstText", e)} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" style={{color:'red'}}  className="col-sm-2 col-form-label">Nội dung số 1(Tiếng Anh)</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName1" placeholder='' onChange={(e) => handleChange(ikey, "firstTextEn", e)} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Nội dung số 2</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "secondText", e)} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" style={{color:'red'}}  className="col-sm-2 col-form-label">Nội dung số 2(Tiếng Anh)</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName2" placeholder='' onChange={(e) => handleChange(ikey, "secondTextEn", e)} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Các thẻ Css</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName3" placeholder='' onChange={(e) => handleChange(ikey, "css", e)} />
                    </div>
                </Form.Group>
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Nguồn link</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="idInputName4" placeholder='' onChange={(e) => handleChange(ikey, "webLink", e)} />
                    </div>
                </Form.Group>
                {/* <Form.Group as={Row} className="mb-3">
                    <Form.Label className="col-sm-2 col-form-label">
                       Lấy đường dẫn ảnh
                    </Form.Label>
                    <Col sm="10">
                        <InputFile name="imageThumbnail"
                            accept={acceptImage}
                            onChange={(e) => handleChange(ikey, "image", e)}
                        />
                        <div
                            style={{
                                display: 'block',
                                color: '#000000'
                            }}
                            className="invalid-feedback"
                        >
                            {value.imageThumbTabPath}
                        </div>
                        <div
                            style={{
                                display: 'block',
                                color: '#000000'
                            }}
                            className="invalid-feedback"
                        >
                            {value.imageTabPath}
                        </div>
                    </Col>
                </Form.Group> */}
                <Form.Group as={Row} className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Ảnh hiển thị</label>
                    <div className="col-sm-10">
                        {
                            value.mediaLink ?
                                <div style={{ width: '200px', height: '200px' }}>
                                    <Image
                                        src={
                                            isValidUrl(value.mediaLink) ? value.mediaLink : BaseGatewayImage + value.mediaLink
                                        }
                                        thumbnail
                                    />
                                </div>
                                : null
                        }
                        <input type="text" className="form-control" id="idInputName5" placeholder='Đường dẫn ảnh' onChange={(e) => handleChange(ikey, "mediaLink", e)} />
                    </div>
                </Form.Group>
            </Form>
        </div>
    );
}

export default DynamicInput;