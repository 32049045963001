import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const ConfirmModal = (props) => {
    const {
        isOpen,
        confirmType,
        content,
        handleAccept,
        handleCancel,
        message = ''
    } = props;

    let contentDis = 'Bạn chắc chắn xóa dữ liệu này';
    if (message.length > 0) {
        contentDis = message;
    } else {
        switch (confirmType) {
            case 'delete':
                contentDis = `Bạn chắc chắn xóa bản ghi ${content}`;
                break;
            case 'send':
                contentDis = 'Bạn chắc chắn gửi email không?';
                break;
            default:
                break;
        }
    }

    // // code modal không sử dụng thư viện
    //  const showModal = document.createElement('div');
    //  const root = document.getElementById("root");
    //  if (isOpen) {
    //   showModal.classList.add("modal-backdrop");
    //   showModal.classList.add("fade");
    //   showModal.classList.add("show");
    //   showModal.setAttribute("id", "id_showModal");
    //   root.appendChild(showModal);
    //  } else {
    //   const child = document.getElementById("id_showModal");
    //   if (child) {
    //     root.removeChild(child);
    //    }
    //  }

    return (
        // code modal không sử dụng thư viện
        // <div className="modal fade show" id="modal-danger" style={{ display: isOpen ? 'block' : 'none', paddingRight: 17 }} aria-modal="true" role="dialog">
        //   <div className="modal-dialog">
        //     <div className="modal-content bg-danger">
        //       <div className="modal-header">
        //         <h4 className="modal-title">Xác nhận thao tác</h4>
        //         <button onClick={handleCancel} type="button" className="close" data-dismiss="modal" aria-label="Close">
        //           <span aria-hidden="true">×</span>
        //         </button>
        //       </div>
        //       <div className="modal-body">
        //         <p>{contentDis}</p>
        //       </div>
        //       <div className="modal-footer justify-content-between">
        //         <button type="button" onClick={handleCancel} className="btn btn-outline-light" data-dismiss="modal">Hủy</button>
        //         <button type="button" onClick={handleAccept} className="btn btn-outline-light">Đồng ý</button>
        //       </div>
        //     </div>
        //   </div>
        // </div>

        <Modal
            show={isOpen}
            onHide={handleCancel}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Xác nhận thao tác</Modal.Title>
            </Modal.Header>
            <Modal.Body>{contentDis}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Hủy
                </Button>
                <Button variant="primary" onClick={handleAccept}>
                    Đồng ý
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
