import React, { useEffect, useState } from 'react';
import {
    Tab,
    Tabs,
    Card,
    Form,
    Row,
    Col,
    ButtonGroup,
    Button,
    Table
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { messageError, messageToast } from '../../utils/define';
import { URI_INTERNAL } from '../../utils/pathLocations';
import { createUrlWithParam } from '../../utils/helper';
import {
    updateAccountAction,
    detailAccountPagingAction,
    createAccountDetailAction,
    getPagingAccountProjectAction,
    addAccountProjectAction,
    updateAccountProjectAction,
    deleteAccountProjectAction
} from '../../store/reducers/account';
import InputFile from '@app/components/input-file/InputFile';
import { BaseGatewayImage } from '@app/utils/apiUrl';
import { DateTime } from 'luxon';
import { isValidUrl } from '@app/utils/helper';
import DocumentInput from '@app/components/document-input/DocumentInput';
import Pagination from 'react-js-pagination';
import { ConfirmModal } from '@app/modules/modal/ConfirmModal';

const acceptImage = 'image/png,image/jpg,image/jpeg,image/gif';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    roles: yup.array().min(1, messageError.emptyMessage),
    gender: yup
        .string()
        .oneOf(['Male', 'Female'], 'Gender is required')
        .required('Gender is required'),
    password: yup
        .string()
        .min(6, messageError.minLength6Message)
        .max(30, messageError.maxLength30Message),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], messageError.likePassword)
        .when('password', {
            is: (value) => value && value.length > 0,
            then: yup.string().required(messageError.emptyMessage)
        })
});

const AccountDetail = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('accountInfo');
    const [idAccount, setIdAccount] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editingProject, setEditingProject] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(4);
    const [idWantDelete, setIdWantDelete] = useState('');
    const [confirmDelMessage, setConfirmDelMessage] = useState('');
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const detailAccount = useSelector((state) => state.account.detailAccount);
    const listProjectAccount = useSelector(
        (state) => state.account.listProjectAccount
    );

    useEffect(() => {
        const paramUrl = new URLSearchParams(location.search.substring(1));
        if (paramUrl.get('id') != null) {
            const id = paramUrl.get('id');
            setIdAccount(id);
            dispatch(detailAccountPagingAction(id));
            dispatch(
                getPagingAccountProjectAction({
                    accountId: id,
                    pageIndex: page,
                    pageSize
                })
            ); // Lấy danh sách project theo accountId
        }
    }, [location, dispatch, page, pageSize]);

    const handleUpdate = async (values, actions) => {
        const model = { ...values, accountId: idAccount };
        model.birthDate = values.birthDate
            ? DateTime.fromJSDate(values.birthDate).toISODate()
            : null; // Sử dụng toISODate để chuyển đổi thành định dạng ISO
        const modelUpdate = {
            data: model
        };
        console.log('Đã vào handle', modelUpdate);
        try {
            const result = await dispatch(
                createAccountDetailAction(modelUpdate)
            );
            if (result.payload && result.payload.errors) {
                if (result.payload.type === 'Email') {
                    actions.setErrors({
                        email: result.payload.errors.content[0]
                    });
                }
                toast.error(result.payload.errors.content[0]);
            } else if (result.payload === 200) {
                toast.success(messageToast.messageToastUpdateSuccess);
                history.goBack();
            }
        } catch (error) {
            toast.error(messageToast.messageToastTryCatch);
        }
    };
    const deleteAction = (id, name) => {
        setIdWantDelete(id);
        setConfirmDelMessage(name);
        setOpenConfirmDeleteModal(true);
    };
    const cancelDelete = () => {
        setIdWantDelete('');
        setOpenConfirmDeleteModal(false);
    };
    const acceptDelete = async () => {
        console.log('Đã vào hàm delete', idWantDelete);
        if (idWantDelete !== '') {
            try {
                const result = await dispatch(
                    
                    deleteAccountProjectAction(idWantDelete)
                );
                if (result.payload.errors) {
                    toast.error(result.payload.errors.content[0]);
                }
                if (result.payload === 200) {
                    toast.success(messageToast.messageToastDeleteSuccess);
                    history.goBack();
                }
            } catch (error) {
                toast.error(messageToast.messageToastTryCatch);
            }
            setOpenConfirmDeleteModal(false);
        }
    };
    const changePage = (number) => {
        setPage(number);
    };

    const onChangeShowNumber = (e) => {
        setPageSize(e.target.value);
    };

    if (!detailAccount) {
        return <div>Loading...</div>;
    }
    useEffect(() => {
        const savedTab = localStorage.getItem('activeTab');
        if (savedTab) {
            setActiveTab(savedTab);
        }
    }, []);

    const handleSelect = (tabKey) => {
        setActiveTab(tabKey);
        localStorage.setItem('activeTab', tabKey);
    };
    const initialValues = {
        name: detailAccount.name || '',
        email: detailAccount.email || '',
        phone: detailAccount.phone || '',
        gender: detailAccount.gender || '',
        birthDate: detailAccount.birthDate
            ? new Date(detailAccount.birthDate)
            : null, // Đảm bảo birthDate là đối tượng Date
        address: detailAccount.address || '',
        numberIdentyti: detailAccount.numberIdentyti || '',
        fontIdentyti: detailAccount.fontIdentyti || '',
        backIdentyti: detailAccount.backIdentyti || '',
        documentLink: detailAccount.documentLink || '',
        avatar: detailAccount.avatar || '',
        portrait: detailAccount.portrait || '',
        job: detailAccount.job || '',
        company: detailAccount.company || '',
        addressCompany: detailAccount.addressCompany || '',
        description: detailAccount.description || '',
        achievement: detailAccount.achievement || ''
    };
    function validURL(str) {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
            'i'
        ); // fragment locator
        return !!pattern.test(str);
    }

    const renderUrlVideo = (urlVideo) => {
        if (!validURL(urlVideo)) return BaseGatewayImage + urlVideo;
        const url = new URL(urlVideo);
        if (url.hostname === 'www.youtube.com' && urlVideo.includes('watch?v=')
        ) {
            return urlVideo.replace('watch?v=', 'embed/');
        }
        // return urlVideo;
        return  urlVideo;
    };
    return (
        <>
        <Tabs activeKey={activeTab} onSelect={handleSelect} id="account-detail-tabs">
                <Tab eventKey="accountInfo" title="Thông tin tài khoản">
                    <Card>
                        <Card.Header as="h5">Thông tin tài khoản</Card.Header>
                        <Card.Body>
                            <Formik
                                enableReinitialize
                                validationSchema={schema}
                                onSubmit={(values, actions) => {
                                    handleUpdate(values, actions);
                                }}
                                initialValues={initialValues}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    errors,
                                    setFieldValue
                                }) => (
                                    <Form
                                        noValidate
                                        onSubmit={(e) => {
                                            handleSubmit(e);
                                        }}
                                    >
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Họ và tên
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    type="input"
                                                    placeholder="Họ và tên"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Số điện thoại
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    type="input"
                                                    placeholder="Số điện thoại"
                                                    name="phone"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Giới tính
                                            </Form.Label>
                                            <Col
                                                sm="10"
                                                className="d-flex align-items-end"
                                            >
                                                <div className="form-check form-check-inline">
                                                    <Form.Check
                                                        type="radio"
                                                        name="gender"
                                                        id="male"
                                                        label="Nam"
                                                        value="Male"
                                                        checked={
                                                            values.gender ===
                                                            'Male'
                                                        }
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!errors.gender
                                                        }
                                                    />
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <Form.Check
                                                        type="radio"
                                                        name="gender"
                                                        id="female"
                                                        label="Nữ"
                                                        value="Female"
                                                        checked={
                                                            values.gender ===
                                                            'Female'
                                                        }
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            !!errors.gender
                                                        }
                                                    />
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gender}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Ngày sinh
                                            </Form.Label>
                                            <Col sm="10">
                                                <DatePicker
                                                    selected={
                                                        values.birthDate &&
                                                        !isNaN(
                                                            new Date(
                                                                values.birthDate
                                                            ).getTime()
                                                        )
                                                            ? new Date(
                                                                  values.birthDate
                                                              )
                                                            : null
                                                    }
                                                    onChange={(date) =>
                                                        setFieldValue(
                                                            'birthDate',
                                                            date
                                                        )
                                                    }
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="Chọn ngày sinh"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.birthDate}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Địa chỉ
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    type="input"
                                                    placeholder="Địa chỉ"
                                                    name="address"
                                                    value={values.address}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Số CMND/CCCD
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    type="input"
                                                    placeholder="Số CMND/CCCD"
                                                    name="numberIdentyti"
                                                    value={
                                                        values.numberIdentyti
                                                    }
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Ảnh CMND/CCCD
                                            </Form.Label>
                                            <Col sm="10">
                                                <p>Ảnh CMND/CCCD mặt trước</p>
                                                <InputFile
                                                    enableReinitialize={true}
                                                    values={
                                                        values.fontIdentyti
                                                            ? [
                                                                  isValidUrl(
                                                                      values.fontIdentyti
                                                                  )
                                                                      ? values.fontIdentyti
                                                                      : BaseGatewayImage +
                                                                        values.fontIdentyti
                                                              ]
                                                            : []
                                                    }
                                                    name="fontIdentyti"
                                                    accept={acceptImage}
                                                    onChange={(files) => {
                                                        if (files.length > 0) {
                                                            setFieldValue(
                                                                'fontIdentyti',
                                                                files[0]
                                                                    .filePath,
                                                                true
                                                            );
                                                        }
                                                    }}
                                                />

                                                <div
                                                    style={{
                                                        display: 'block',
                                                        color: '#dc3545'
                                                    }}
                                                    className="invalid-feedback"
                                                >
                                                    {errors.fontIdentyti}
                                                </div>
                                                <p>Ảnh CMND/CCCD mặt sau</p>
                                                <InputFile
                                                    enableReinitialize={true}
                                                    values={
                                                        values.backIdentyti
                                                            ? [
                                                                  isValidUrl(
                                                                      values.backIdentyti
                                                                  )
                                                                      ? values.backIdentyti
                                                                      : BaseGatewayImage +
                                                                        values.backIdentyti
                                                              ]
                                                            : []
                                                    }
                                                    name="backIdentyti"
                                                    accept={acceptImage}
                                                    onChange={(files) => {
                                                        if (files.length > 0) {
                                                            setFieldValue(
                                                                'backIdentyti',
                                                                files[0]
                                                                    .filePath,
                                                                true
                                                            );
                                                        }
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: 'block',
                                                        color: '#dc3545'
                                                    }}
                                                    className="invalid-feedback"
                                                >
                                                    {errors.backIdentyti}
                                                </div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Đơn đã nộp
                                            </Form.Label>
                                            <Col sm="10">
                                                <DocumentInput
                                                    isInvalid={
                                                        !!errors.documentLink
                                                    }
                                                    errorsValue={
                                                        errors.documentLink
                                                    }
                                                    value={values.documentLink}
                                                    onChangeProp={(value) => {
                                                        setFieldValue(
                                                            'documentLink',
                                                            value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Ảnh đại diện
                                            </Form.Label>
                                            <Col sm="10">
                                                <InputFile
                                                    enableReinitialize={true}
                                                    values={
                                                        values.avatar
                                                            ? [
                                                                  isValidUrl(
                                                                      values.avatar
                                                                  )
                                                                      ? values.avatar
                                                                      : BaseGatewayImage +
                                                                        values.avatar
                                                              ]
                                                            : []
                                                    }
                                                    name="avatar"
                                                    accept={acceptImage}
                                                    onChange={(files) => {
                                                        if (files.length > 0) {
                                                            setFieldValue(
                                                                'avatar',
                                                                files[0]
                                                                    .filePath,
                                                                true
                                                            );
                                                        }
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: 'block',
                                                        color: '#dc3545'
                                                    }}
                                                    className="invalid-feedback"
                                                >
                                                    {errors.avatar}
                                                </div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Ảnh chân dung
                                            </Form.Label>
                                            <Col sm="10">
                                                <InputFile
                                                    enableReinitialize={true}
                                                    values={
                                                        values.portrait
                                                            ? [
                                                                  isValidUrl(
                                                                      values.portrait
                                                                  )
                                                                      ? values.portrait
                                                                      : BaseGatewayImage +
                                                                        values.portrait
                                                              ]
                                                            : []
                                                    }
                                                    name="portrait"
                                                    accept={acceptImage}
                                                    onChange={(files) => {
                                                        if (files.length > 0) {
                                                            setFieldValue(
                                                                'portrait',
                                                                files[0]
                                                                    .filePath,
                                                                true
                                                            );
                                                        }
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: 'block',
                                                        color: '#dc3545'
                                                    }}
                                                    className="invalid-feedback"
                                                >
                                                    {errors.portrait}
                                                </div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Công việc hiện tại
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    type="input"
                                                    placeholder="Job"
                                                    name="job"
                                                    value={values.job}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Công ty
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    type="input"
                                                    placeholder="Company"
                                                    name="company"
                                                    value={values.company}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Giới thiệu bản thân
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    type="input"
                                                    placeholder="Description"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Thành tích đạt được{' '}
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    type="input"
                                                    placeholder="Achievement"
                                                    name="achievement"
                                                    value={values.achievement}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Col md={{ span: 10, offset: 2 }}>
                                                <ButtonGroup className="mb-2">
                                                    <Button
                                                        type="submit"
                                                        theme="primary"
                                                    >
                                                        Cập nhật
                                                    </Button>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <Button
                                                        onClick={() =>
                                                            history.goBack()
                                                        }
                                                        theme="secondary"
                                                    >
                                                        Hủy
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </Tab>

                <Tab eventKey="otherInfo" title="Bài đăng">
                    <div className="d-flex justify-content-between mb-3">
                        <form className="form-inline">
                            <label htmlFor="pageSize" className="mr-2">
                                Hiển thị:
                            </label>
                            <select
                                id="pageSize"
                                className="form-control"
                                value={pageSize}
                                onChange={(e) => onChangeShowNumber(e)}
                            >
                                {[4, 8, 12].map((size) => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </form>
                        <NavLink
                            className="nav-link-reject nav-link"
                            to={createUrlWithParam(
                                URI_INTERNAL.ACCOUNT_PROJECT_PATH_ADD,
                                {
                                    id: idAccount
                                }
                            )}
                        >
                            <button type="button" className="btn btn-info">
                                Thêm mới
                            </button>
                        </NavLink>
                    </div>
                    <Table striped bordered hover>
                        <thead>
                            <tr role="row">
                                <th className="sorting">STT</th>
                                <th className="sorting">Tiêu đề</th>
                                <th className="sorting" style={{ width: '20%' }}>
                                    Nội dung
                                </th>
                                <th className="sorting" style={{ width: '20%' }}>
                                    Video
                                </th>
                                <th className="sorting" style={{ width: '20%' }}>
                                    Ảnh
                                </th>   
                               
                                <th className="sorting">Hành động</th>
                            </tr>
                        </thead>
                        {listProjectAccount && listProjectAccount.length > 0 ? (
                            <tbody>
                                {listProjectAccount.map((item, index) => (

                                    <tr key={index} className="odd">
                                        <td className="dtr-control sorting_1">
                                            {index + 1 + (page - 1) * pageSize}
                                        </td>
                                        <td>{item.title}</td>
                                        <td style={{ width: '20%' }}>
                                            {item.content}
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    paddingBottom: '56.25%',
                                                    height: 0,
                                                    overflow: 'hidden',
                                                    maxWidth: '100%',
                                                    background: '#000'
                                                }}
                                            >
                                                <iframe
                                                    className="mb-1"
                                                    // style={{ display: validURL(videoUrl) ? 'block' : 'none' }}
                                                    style={{ display: item.linkDocument ? 'block' : 'none', }}
                                                    src={renderUrlVideo(item.linkDocument)}
                                                    title="This is a unique title"
                                                >
                                                    { }
                                                </iframe>
                                            </div>
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            {/* {item.imagePath} */}
                                            <img
                                                src={BaseGatewayImage + item.imagePath}
                                                alt="Ảnh"
                                                style={{
                                                    width: '100%',
                                                    height: '170px',
                                                    objectFit: 'contain', 
                                                    maxHeight: '200px'
                                                }}
                                            />
                                        </td>
                                        
                                        <td>
                                            <ul className="nav">
                                                <li className="nav-item"></li>
                                                <li className="nav-item">
                                                    <NavLink
                                                        to={createUrlWithParam(
                                                            URI_INTERNAL.ACCOUNT_PROJECT_PATH_EDIT,
                                                            {
                                                                id: item.id
                                                            }
                                                        )}
                                                        exact
                                                        className="nav-link"
                                                    >
                                                        <i
                                                            title="Sửa"
                                                            className="nav-icon font-icon fas fa-pen"
                                                        />
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink
                                                        onClick={() =>
                                                            deleteAction(
                                                                item.id,
                                                                item.title
                                                            )
                                                        }
                                                        to="#"
                                                        exact
                                                        className="nav-link"
                                                    >
                                                        <i
                                                            title="Xóa"
                                                            className="nav-icon font-icon far fa-trash-alt"
                                                        />
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="txt-center">
                                    <td colSpan="12">Không có dữ liệu</td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                    <div className="d-flex justify-content-center">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={page}
                            itemsCountPerPage={Number(pageSize)}
                            totalItemsCount={listProjectAccount.length}
                            pageRangeDisplayed={5}
                            onChange={(number) => changePage(number)}
                        />
                    </div>
                </Tab>
                
            </Tabs>
            <ConfirmModal
                    isOpen={openConfirmDeleteModal}
                    confirmType="delete"
                    handleAccept={() => acceptDelete()}
                    handleCancel={() => cancelDelete()}
                    content={confirmDelMessage}
                />
        </>
    );
};

export default AccountDetail;
