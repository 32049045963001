import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import SelectTree from '@app/components/select-tree/SelectTree';
import { BANNER_API, CATEGORY_API } from '@app/utils/apiUrl';
import { Get, Post, Put } from '@app/utils/httpProvider';
import { useMutation, useQuery } from 'react-query';
import { NIL as NIL_UUID, stringify } from 'uuid';
import { URI_INTERNAL } from '../../utils/pathLocations';
import {
    acceptImage,
    positionBanner,
    messageToast,
    status200,
    messageError
} from '../../utils/define';
import { BaseGatewayImage } from '../../utils/apiUrl';
import { Button } from '@app/components/index';

const schema = yup.object().shape({
    categoryIds: yup.string().required(messageError.emptyMessage)
});

const Banner = () => {
    const history = useHistory();
    const location = useLocation();

    const [cateId, setCateId] = useState('null');

    const [values, setValues] = useState({
        categoryId: 'null',
        header: [],
        bothSides: [],
        rightColumn: [],
        center: [],
        background: [],
        centersilde:[]
    });

    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY, null, {})
    );

    const itemBanner = useQuery(['itemBanner', cateId], () =>
        Get(
            BANNER_API.GET_ITEM_BY_CATE_API,
            cateId === 'null' ? NIL_UUID : cateId,
            null
        )
    );

    const addBanner = useMutation((value) =>
        Post(BANNER_API.CREATE_BANNER_API, null, value)
    );

    const updateBanner = useMutation((value) =>
        Put(BANNER_API.UPDATE_BANNER_API, value.id, value)
    );

    let listMenu = [
        {
            name: 'Tất cả',
            id: 'null',
            parentId: NIL_UUID
        }
    ];
    if (listCate.data) {
        listMenu = listMenu.concat(listCate.data.data.$values);
    }

    useEffect(() => {
        if (
            itemBanner.data &&
            itemBanner.data.categoryId !== values.categoryId
        ) {
            const valuesNew = {
                categoryId: itemBanner.data.categoryId,
                header: [...itemBanner.data.header.$values],
                background: [...itemBanner.data.background.$values],
                bothSides: [...itemBanner.data.bothSides.$values],
                rightColumn: [...itemBanner.data.rightColumn.$values],
                center: [...itemBanner.data.center.$values],
                centersilde:[...itemBanner.data.centersilde.$values]
            };
            setValues(valuesNew);
        }
    }, [itemBanner]);

    useEffect(() => {
        if (cateId !== values.categoryId) {
            itemBanner.refetch();
        }
    }, [cateId]);

    const handleSave = () => {
        values.categoryId = cateId === 'null' ? itemBanner.data ? itemBanner.data.categoryId : '' : cateId;
        if (itemBanner.data.id !== NIL_UUID) {
            // const model = {
            //     id: itemBanner.id,
            //     data: values
            // };
            values.id = itemBanner.data.id;
            updateBanner.mutate(values, {
                onSuccess: (respone) => {
                    try {
                        if (respone.status === status200) {
                            itemBanner.refetch();
                            toast.success(
                                messageToast.messageToastUpdateSuccess
                            );
                        } else {
                            toast.errors(messageToast.messageToastUpdateError);
                        }
                    } catch (error) {
                        toast.error(messageToast.messageToastTryCatch);
                    }
                },
                onError: () => {
                    // bắt lỗi khi gặp ngoại lệ
                }
            });
        } else {
            addBanner.mutate(values, {
                onSuccess: (respone) => {
                    try {
                        if (respone.status === status200) {
                            itemBanner.refetch();
                            toast.success(
                                messageToast.messageToastCreateSuccess
                            );
                        } else {
                            toast.errors(messageToast.messageToastCreateError);
                        }
                    } catch (error) {
                        toast.error(messageToast.messageToastTryCatch);
                    }
                },
                onError: () => {
                    // bắt lỗi khi gặp ngoại lệ
                }
            });
        }
    };

    const handleChangeImage = (propertyName, files, banner, order) => {
        const valuesNew = { ...values };
        if (files.length > 0) {
            if (banner) {
                banner.imagePath = files[0].filePath;
                banner.imageThumb = files[0].thumbPath;
                valuesNew[propertyName].map((x) => {
                    if (x.orderNumber === banner.orderNumber) {
                        x = banner;
                    }
                    return x;
                });
            } else {
                const banner = {
                    imagePath: files[0].filePath,
                    imageThumb: files[0].thumbPath,
                    linkRedirect: '',
                    orderNumber: order
                };
                valuesNew[propertyName].push(banner);
            }
        } else {
            // code mới ren tự động item
            valuesNew[propertyName].map((x) => {
                if (x.orderNumber === banner.orderNumber) {
                    x.imagePath = "";
                    x.imageThumb = "";
                }
                return x;
            });

            // code cũ fix cứng item
            // valuesNew[propertyName] = banner
            //     ? valuesNew[propertyName].filter(
            //         (x) => x.orderNumber !== banner.orderNumber
            //     )
            //     : valuesNew[propertyName];
        }
        setValues(valuesNew);

    };

    const handleLinkRedirect = (propertyName, value, banner) => {
        const valuesNew = { ...values };
        banner.linkRedirect = value;
        valuesNew[propertyName].map((x) => {
            if (x.orderNumber === banner.orderNumber) {
                x = banner;
            }
            return x;
        });
        setValues(valuesNew);
    };
    const handleVideoLink = (propertyName, value, banner) => {
        const valuesNew = { ...values };
        banner.videoLink = value;
        valuesNew[propertyName].map((x) => {
            if (x.orderNumber === banner.orderNumber) {
                x = banner;
            }
            return x;
        });
        setValues(valuesNew);
    }
    const handleTitle = (propertyName, value, banner) => {
        const valuesNew = { ...values };
        banner.title = value;
        valuesNew[propertyName].map((x) => {
            if (x.orderNumber === banner.orderNumber) {
                x = banner;
            }
            return x;
        });
        setValues(valuesNew);
    }
    const handleDes = (propertyName, value, banner) => {
        const valuesNew = { ...values };
        banner.des = value;
        valuesNew[propertyName].map((x) => {
            if (x.orderNumber === banner.orderNumber) {
                x = banner;
            }
            return x;
        });
        setValues(valuesNew);
    }
    // code mới
    const onClickDelete = (propertyName, itemBanner, event) => {
        const valuesNews = JSON.parse(JSON.stringify(values))
        valuesNews[propertyName] = valuesNews[propertyName].filter((X) => X.orderNumber !== itemBanner.orderNumber);
        valuesNews[propertyName].map((item) => {
            if (item.orderNumber > itemBanner.orderNumber) {
                item.orderNumber = item.orderNumber - 1;
            }
            return item;
        })
        setValues(valuesNews);
    }

    // code mới
    const onClickAdd = (propertyName) => {
        const valuesNews = JSON.parse(JSON.stringify(values));
        var orderNumberMax = 0;
        if (valuesNews[propertyName].length > 0) {
            orderNumberMax = Math.max(...valuesNews[propertyName].map(o => parseInt(o.orderNumber)));
        }
        const bannerNew = {
            imagePath: "",
            imageThumb: "",
            linkRedirect: '',
            orderNumber: orderNumberMax + 1
        };
        valuesNews[propertyName].push(bannerNew);
        setValues(valuesNews);
    }

    // const bannerHeader = values.header[0];
    // const bannerBackground = values.background[0];
    // const bannerRunLeft = values.bothSides.find((x) => x.orderNumber === 1);
    // const bannerRunRight = values.bothSides.find((x) => x.orderNumber === 2);
    // const bannerColRight1 = values.rightColumn.find((x) => x.orderNumber === 1);
    // const bannerColRight2 = values.rightColumn.find((x) => x.orderNumber === 2);
    // const bannerColRight3 = values.rightColumn.find((x) => x.orderNumber === 3);
    // const bannerColRight4 = values.rightColumn.find((x) => x.orderNumber === 4);
    // const bannerColRight5 = values.rightColumn.find((x) => x.orderNumber === 5);
    // const bannerColRight6 = values.rightColumn.find((x) => x.orderNumber === 6);
    // const bannerColRight7 = values.rightColumn.find((x) => x.orderNumber === 7);
    // const bannerColRight8 = values.rightColumn.find((x) => x.orderNumber === 8);
    // const bannerColRight9 = values.rightColumn.find((x) => x.orderNumber === 9);
    // const bannerColRight10 = values.rightColumn.find((x) => x.orderNumber === 10);
    // const bannerColRight11 = values.rightColumn.find((x) => x.orderNumber === 11);
    // const bannerColRight12 = values.rightColumn.find((x) => x.orderNumber === 12);
    // const bannerCenter1 = values.center.find((x) => x.orderNumber === 1);
    // const bannerCenter2 = values.center.find((x) => x.orderNumber === 2);
    // const bannerCenter3 = values.center.find((x) => x.orderNumber === 3);

    // const renderBannerItem = (propertyName, title, banner, order) => {
    //     return (
    //         <>
    //             <div className="mb-2">{title}</div>
    //             <InputFile
    //                 accept={acceptImage}
    //                 enableReinitialize
    //                 values={
    //                     banner ? [BaseGatewayImage + banner.imageThumb] : []
    //                 }
    //                 onChange={(files) => {
    //                     handleChangeImage(propertyName, files, banner, order);
    //                 }}
    //             />
    //             <Form.Control
    //                 placeholder="Đường dẫn"
    //                 type="text"
    //                 name="redirectLink"
    //                 className="mt-2"
    //                 value={banner ? banner.linkRedirect : ''}
    //                 disabled={banner === null || banner === undefined}
    //                 style={{
    //                     width: 200
    //                 }}
    //                 onChange={(e) => {
    //                     handleLinkRedirect(
    //                         propertyName,
    //                         e.target.value,
    //                         banner
    //                     );
    //                 }}
    //             />
    //         </>
    //     );
    // };

    //code mới
    const bannerHeaders = values.header;
    const bannerBackgrounds = values.background;
    const bannerBothSides = values.bothSides;
    const bannerRights = values.rightColumn;
    const bannerCenters = values.center;
    const bannerCenterSildes= values.centersilde;


    // code mới
    const renderBannerItemList = (propertyName, title, banner) => {
        return (
            <>
                {
                    banner.map((item, index) =>
                        <Col>
                            <div key={index} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <span>
                                    {title} {item.orderNumber}
                                </span>
                                <i onClick={(event) => onClickDelete(propertyName, item, event)} style={{ cursor: 'pointer', marginLeft: '15px' }} title="Xóa" class="nav-icon font-icon far fa-trash-alt"></i>
                            </div>
                            <InputFile
                                accept={acceptImage}
                                enableReinitialize
                                values={
                                    item ? [BaseGatewayImage + item.imageThumb] : []
                                }
                                onChange={(files) => {
                                    handleChangeImage(propertyName, files, item, item.orderNumber);
                                }}
                            />
                            <Form.Control
                                placeholder="Đường dẫn nguồn"
                                type="text"
                                name="redirectLink"
                                className="mt-2"
                                value={item ? item.linkRedirect : ''}
                                disabled={item === null || item === undefined}
                                style={{
                                    width: 200
                                }}
                                onChange={(e) => {
                                    handleLinkRedirect(
                                        propertyName,
                                        e.target.value,
                                        item
                                    );
                                }}
                            />
                             <Form.Control
                                placeholder="Link video"
                                type="text"
                                name="videoLink"
                                className="mt-2"
                                value={item ? item.videoLink : ''}
                                disabled={item === null || item === undefined}
                                style={{
                                    width: 200
                                }}
                                onChange={(e) => {
                                    handleVideoLink(
                                        propertyName,
                                        e.target.value,
                                        item
                                    );
                                }}
                            />
                             <Form.Control
                                placeholder="Tiêu đề"
                                type="text"
                                name="title"
                                className="mt-2"
                                value={item ? item.title : ''}
                                disabled={item === null || item === undefined}
                                style={{
                                    width: 200
                                }}
                                onChange={(e) => {
                                    handleTitle(
                                        propertyName,
                                        e.target.value,
                                        item
                                    );
                                }}
                            />
                              <Form.Control
                                placeholder="Mô tả"
                                type="text"
                                name="des"
                                className="mt-2"
                                value={item ? item.des : ''}
                                disabled={item === null || item === undefined}
                                style={{
                                    width: 200
                                }}
                                onChange={(e) => {
                                    handleDes(
                                        propertyName,
                                        e.target.value,
                                        item
                                    );
                                }}
                            />
                            
                        </Col>
                    )
                }
                <div>
                    <Button onClick={() => onClickAdd(propertyName)} style={{ marginTop: '10px', maxWidth: '200px' }}>Thêm mới</Button>
                </div>

            </>
        );
    };

    return (
        <div className="container-fluid pb-2 pr-3">
            <Card>
                <Card.Header>
                    <div className="d-flex justify-content-between align-items-center">
                        <SelectTree
                            data={listMenu}
                            showSearchBox
                            Placeholder="Danh mục"
                            positionExpand="right"
                            singleValueSelected={listMenu.find(
                                (x) => x.id === cateId
                            )}
                            onChange={(value) => {
                                setCateId(value.id);
                            }}
                        />
                        <div className="d-flex">
                            <NavLink
                                className="nav-link-reject nav-link"
                                to={URI_INTERNAL.BANNER_PATH}
                            >
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={() => handleSave()}
                                >
                                    Lưu
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </Card.Header>
            </Card>

            {/* Code mới */}
            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo Header</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-2">
                                {renderBannerItemList(
                                    'header',
                                    'Quảng cáo Header',
                                    bannerHeaders
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo trung tâm dạng Silde</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-2">
                                {renderBannerItemList(
                                    'centersilde',
                                    'Quảng cáo trung tâm dạng Silde',
                                    bannerCenterSildes
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo trung tâm danh sách bài viết</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-2">
                                {renderBannerItemList(
                                    'center',
                                    'Quảng cáo trung tâm danh sách bài viết',
                                    bannerCenters
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo cột phải</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-2">
                                {renderBannerItemList(
                                    'rightColumn',
                                    'Quảng cáo cột phải',
                                    bannerRights
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo Background</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-2">
                                {renderBannerItemList(
                                    'background',
                                    'Quảng cáo background',
                                    bannerBackgrounds
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo hai bên</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-2">
                                {renderBannerItemList(
                                    'bothSides',
                                    'Quảng cáo chạy hai bên',
                                    bannerBothSides
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>




            {/* <Row>
                <Col xs={3}>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo Header</h5>
                        </Card.Header>
                        <Card.Body>
                            {renderBannerItem(
                                'header',
                                'Quảng cáo Header',
                                bannerHeader,
                                1
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={3}>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo Background</h5>
                        </Card.Header>
                        <Card.Body>
                            {renderBannerItem(
                                'background',
                                'Quảng cáo Background',
                                bannerBackground,
                                1
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <h5>Quảng cáo chạy 2 bên</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    {renderBannerItem(
                                        'bothSides',
                                        'Bên trái',
                                        bannerRunLeft,
                                        1
                                    )}
                                </Col>
                                <Col>
                                    {renderBannerItem(
                                        'bothSides',
                                        'Bên phải',
                                        bannerRunRight,
                                        2
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Card>
                <Card.Header>
                    <h5>Quảng cáo trung tâm</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col>
                            {renderBannerItem(
                                'center',
                                'Quảng cáo trung tâm 1',
                                bannerCenter1,
                                1
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'center',
                                'Quảng cáo trung tâm 2',
                                bannerCenter2,
                                2
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'center',
                                'Quảng cáo trung tâm 3',
                                bannerCenter3,
                                3
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card>
                <Card.Header>
                    <h5>Quảng cáo cột phải</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 1',
                                bannerColRight1,
                                1
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 2',
                                bannerColRight2,
                                2
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 3',
                                bannerColRight3,
                                3
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 4',
                                bannerColRight4,
                                4
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 5',
                                bannerColRight5,
                                5
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 6',
                                bannerColRight6,
                                6
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 7',
                                bannerColRight7,
                                7
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 8',
                                bannerColRight8,
                                8
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 9',
                                bannerColRight9,
                                9
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 10',
                                bannerColRight10,
                                10
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 11',
                                bannerColRight11,
                                11
                            )}
                        </Col>
                        <Col>
                            {renderBannerItem(
                                'rightColumn',
                                'Quảng cáo cột phải 12',
                                bannerColRight12,
                                12
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card> */}
        </div>
    );
};

export default Banner;
