import { combineReducers } from '@reduxjs/toolkit';
import { authSlice } from './auth';
import { searchSlice } from './search';
import { accountSlice } from './account';
import { newsCrawlerSlice } from './newsCrawler';
import { bannerSlice } from './banner';
import { emailConfigSlice } from './emailConfig';
import { categorySlice } from './category';
import { postSlice } from './post';
import { loginHistorySlice } from './loginHistory';
import { logSlice } from './log';
import { reportPostSlice } from './reportPost';
import { reportAdvertisingAnalysisSlice } from './advertisingAnalysis';

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    search: searchSlice.reducer,
    account: accountSlice.reducer,
    emailConfig: emailConfigSlice.reducer,
    banner: bannerSlice.reducer,
    newsCrawler: newsCrawlerSlice.reducer,
    category: categorySlice.reducer,
    post: postSlice.reducer,
    loginHistory: loginHistorySlice.reducer,
    log: logSlice.reducer,
    reportPost: reportPostSlice.reducer,
    advertisingAnalysis: reportAdvertisingAnalysisSlice.reducer,
});

export default rootReducer;
