import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {toast} from 'react-toastify';
// import {Gatekeeper} from 'gatekeeper-client-sdk';
import {QueryClient, QueryClientProvider} from 'react-query';
import App from '@app/App';
import store from '@store/store';

import './index.scss';
import './i18n';
import 'admin-lte/build/js/AdminLTE';
import * as serviceWorker from './serviceWorker';

toast.configure({
    autoClose: 3000,
    draggable: false,
    position: 'top-right',
    hideProgressBar: false,
    newestOnTop: true,
    closeOnClick: true,
    rtl: false,
    pauseOnVisibilityChange: true,
    pauseOnHover: true
});

// Gatekeeper.configure('08401b7e-da7e-4bf3-a9bf-6f594ae5fb02');

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: 'always',
            // refetchOnReconnect: false,
            // retry: false,
            staleTime: twentyFourHoursInMs
        }
    }
});

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
