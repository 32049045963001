import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Form, Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import VideoInput from '@app/components/video-input/VideoInput';
import { messageError, messageToast } from '../../utils/define';
import {
    updateAccountProjectAction,
    getByIdAccountProjectAction
} from '../../store/reducers/account';
import InputFile from '@app/components/input-file/InputFile';
import { isValidUrl } from '@app/utils/helper';
import { BaseGatewayImage } from '@app/utils/apiUrl';

const schema = yup.object().shape({
    title: yup.string().required(messageError.emptyMessage),
    content: yup.string().required(messageError.emptyMessage)
});
const acceptImage = 'image/png,image/jpg,image/jpeg,image/gif';

const AccountProjectAdd = () => {
    const [idAccount, setIdAccount] = useState('');
    const [imageThumbPath, setImageThumbPath] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const projectAccount = useSelector((state) => state.account.projectAccount);

    useEffect(() => {
        const paramUrl = new URLSearchParams(window.location.search);
        const id = paramUrl.get('id');
        if (id) {
            setIdAccount(id);
            dispatch(getByIdAccountProjectAction(id));
        }
        return () => {
            setIdAccount('');
        };
    }, [dispatch]);

    const handleCreate = async (values, actions) => {
        const model = { ...values };
        const modelUpdate = {
            data: model,
            id: idAccount
        };
        try {
            const result = await dispatch(updateAccountProjectAction(modelUpdate));
            if (result.payload && result.payload.errors) {
                toast.error('Đã có lỗi xảy ra');
            } else if (result.payload === 200) {
                history.goBack();
                toast.success(messageToast.messageToastUpdateSuccess);
            } else {
                toast.error('Đã có lỗi xảy ra');
            }
        } catch (error) {
            toast.error(messageToast.messageToastTryCatch);
        }
    };

    return (
        <Card>
            <Card.Header as="h5">Chỉnh sửa bài đăng</Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={handleCreate}
                    enableReinitialize
                    initialValues={{
                        title: projectAccount?.title || '',
                        content: projectAccount?.content || '',
                        linkDocument: projectAccount?.linkDocument || '',
                        imagePath: projectAccount?.imagePath || '',
                        imageThumbnail: projectAccount?.imageThumbnail || ''
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    }) => {
                        useEffect(() => {
                            if (imageThumbPath) {
                                setFieldValue('imageThumbnail', imageThumbPath, true);
                            }
                        }, [imageThumbPath, setFieldValue]);

                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            placeholder="Tiêu đề"
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nội dung
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="content"
                                            placeholder="Nội dung"
                                            value={values.content}
                                            onChange={handleChange}
                                            isInvalid={!!errors.content}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.content}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Video
                                    </Form.Label>
                                    <Col sm="10">
                                        <VideoInput
                                            value={values.linkDocument}
                                            onChangeProp={(value) =>
                                                setFieldValue('linkDocument', value)
                                            }
                                            isInvalid={!!errors.linkDocument}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.linkDocument}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            labelImage="Upload ảnh"
                                            enableReinitialize={true}
                                            values={
                                                values.imagePath
                                                    ? [
                                                          isValidUrl(values.imagePath)
                                                              ? values.imagePath
                                                              : BaseGatewayImage + values.imagePath
                                                      ]
                                                    : []
                                            }
                                            name="imagePath"
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                if (files.length > 0) {
                                                    setImageThumbPath(files[0].thumbPath);
                                                    setFieldValue('imagePath', files[0].filePath, true);
                                                }
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh Thumbnail
                                    </Form.Label>
                                    <Col sm="10">
                                        {imageThumbPath ? (
                                            <img
                                                height={200}
                                                width={200}
                                                src={BaseGatewayImage + imageThumbPath}
                                                alt="Ảnh"
                                            />
                                        ) : (
                                            <img
                                                height={200}
                                                width={200}
                                                src={BaseGatewayImage + values.imageThumbnail}
                                                alt="Ảnh"
                                            />
                                        )}
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Thêm
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() => history.goBack()}
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        );
                    }}
                </Formik>
            </Card.Body>
        </Card>
    );
};

export default AccountProjectAdd;
