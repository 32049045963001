import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { Button } from '@components';
import { Card, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import DynamicInputEdit from '@pages/landing/DynamicInputEdit';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
    messageError,
    messageToast,
    dataTypeComponent
} from '@app/utils/define';
import { DYNAMICDATA_API } from '@app/utils/apiUrl';
import { Get, Put } from '@app/utils/httpProvider';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const schema = yup.object().shape({
    componentName: yup.string().required(messageError.emptyMessage),
    ikey: yup.string().required(messageError.emptyMessage),
    // dataType: yup.object().required(messageError.emptyMessage),
    orderNumber: yup.number(),
});

const LandingComponentEdit = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const id = paramUrl.get('id');

    const [dataTypeState, setDataTypeState] = useState(dataTypeComponent[0].value);
    const [dynamicData, setDynamicData] = useState("");

    const { isLoading, isError, error, data } = useQuery([id], () => Get(DYNAMICDATA_API.GETBYID, id));

    const saveUpdate = useMutation((model) =>
        Put(DYNAMICDATA_API.UPDATE, id, model)
    );

    const handleChangeDynamicData = (data) => {
        setDynamicData(data);
    }

    const handleEdit = async (values) => {
        const tempModel = { ...values };
        const model = {
            componentName: tempModel.componentName,
            componentNameEn: tempModel.componentNameEn,
            key: tempModel.ikey,
            type: dataTypeState,
            value: dynamicData,
            orderNumber: tempModel.orderNumber
        }
        saveUpdate.mutate(model, {
            onSuccess: (res) => {
                if (res.status !== 200 && res.status !== 201) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    toast.success(messageToast.messageToastUpdateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    React.useEffect(() => {
        if (data && data.type) {
            setDataTypeState(data.type);
        }
        if (data && data.value) {
            setDynamicData(data.value)
        }
    }, [data]);
   console.log("data",data)
    return (
        <Card>
            <Card.Header as="h5">Sửa</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : data == null ? (
                    <div>Component không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            componentName: data && data.componentName ? data.componentName : '',
                            componentNameEn: data && data.componentNameEn ? data.componentNameEn : '',
                            ikey: data && data.key ? data.key : '',
                            orderNumber: data && data.orderNumber ? data.orderNumber : 0,
                            dataType: data && data.type ? dataTypeComponent.filter(x => x.value === data.type) : dataTypeComponent[0],
                            value: data && data.value ? data.value : ''
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        {t('landing.label.componentName')}
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Form.Control
                                                    placeholder=""
                                                    type="text"
                                                    name="componentName"
                                                    value={values.componentName}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.componentName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.componentName}
                                                </Form.Control.Feedback>
                                            </TabPanel>
                                            <TabPanel>
                                                <Form.Control
                                                    placeholder=""
                                                    type="text"
                                                    name="componentNameEn"
                                                    value={values.componentNameEn}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.componentNameEn}
                                                />

                                            </TabPanel>
                                        </Tabs>

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        {t('landing.label.key')}
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder=""
                                            type="text"
                                            name="ikey"
                                            value={values.ikey}
                                            isInvalid={!!errors.ikey}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        {t('landing.label.orderNumber')}
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder=""
                                            type="number"
                                            name="orderNumber"
                                            value={values.orderNumber}
                                            onChange={handleChange}
                                            isInvalid={!!errors.orderNumber}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.orderNumber}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        {t('landing.label.dataType')}
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="dataType"
                                            id="dataType"
                                            placeholder="Kiểu dữ liệu"
                                            value={values.dataType}
                                            isDisabled
                                            options={dataTypeComponent}
                                        />
                                        <div style={{ display: 'block', color: '#dc3545' }} className="invalid-feedback" >
                                            {errors.dataType}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <DynamicInputEdit dataType={dataTypeState} initValue={values.value} handleChangeDynamicData={handleChangeDynamicData} />
                                <Form.Group as={Row} className="offset-sm-2 col-sm-1">
                                    <Button type="submit" theme="primary">
                                        {t('button.save')}
                                    </Button>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card.Body>
        </Card>
    );
};

export default LandingComponentEdit;