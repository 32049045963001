import React, { useState } from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FaFolderOpen, FaFolder } from 'react-icons/fa';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import { Get, Put } from '@app/utils/httpProvider';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
    dataTypeCategory,
    messageError,
    messageToast,
    status200,
    acceptImage
} from '../../utils/define';
import { BaseGatewayImage, PARTMER_API, GIAODIEN_API, CATEGORY_API } from '../../utils/apiUrl';
import ReactSpinner from '../../components/reactSpinner/ReactSpinner';
import SelectionMutil from '../../components/selectionMutil/SelectionMutil';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    key: yup.string().required(messageError.emptyMessage),
});

const GiaodienEdit = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [categoryValues, setCategoryValues] = useState([]);
    const [forceValidate, setForceValidate] = useState(false);
    // danh sách các object dataType đã chọn
    const [dataTypeSelects, setDataTypeSelects] = useState([])
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const id = paramUrl.get('id');

    const { isLoading, isError, error, data } = useQuery([id], () =>
        Get(GIAODIEN_API.GET_ITEM_GIAODIEN, id)
    );

    React.useEffect(() => {
        if (data && data.cateIds && data.cateIds.length > 0) {
            setCategoryValues(JSON.parse(data.cateIds))
        }
    }, [data])

    const editFunc = useMutation((value) =>
        Put(GIAODIEN_API.UPDATE_GIAODIEN, value.id, value.data)
    );

    const handleEdit = async (values) => {
        const model = values;
        model.cateIds = JSON.stringify(dataTypeSelects.map((x) => x.id));
        const modelUpdate = {
            data: model,
            id: id
        };
        editFunc.mutate(modelUpdate, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    setIsLoadingEdit(true);
                    queryClient.setQueryData(id, model);
                    toast.success(messageToast.messageToastUpdateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };
    // lấy danh sách các danh mục
    const dataCategory = useQuery(
        ['categoryData'],
        () =>
            Get(CATEGORY_API.GET_ALL_CATEGORY, null)
    );
    const handleSelectCategory = data => {
        setDataTypeSelects(data);
        if (data) {
            setCategoryValues(data.map(x => x.id));
        }
    };

    let lstCategoryMap = [];
    if (dataCategory && dataCategory.data && dataCategory.data.data) {
        const lstCateInformation = dataCategory.data.data.$values.filter(
            (x) => x.dataType === dataTypeCategory.information || dataTypeCategory.introduce || dataTypeCategory.image || dataTypeCategory.video
        )
        if (lstCateInformation) {
            lstCateInformation.forEach(item => {
                lstCategoryMap.push({
                    id: item.id,
                    parentId: item.parentId,
                    name: item.name,
                    type: item.dataType
                });
            });
        }
    }

    return (
        <Card>
            <Card.Header as="h5">Sửa thông tin thành phần giao diện</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : data == null ? (
                    <div>Đối tác không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            name: data && data.name ? data.name : '',
                            key:
                                data && data.key
                                    ? data.key
                                    : '',
                            cateIds: []
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tên *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tên"
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Key *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Key"
                                            type="text"
                                            name="key"
                                            value={values.key}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Form.Label className="col-sm-2 col-form-label">
                                        <label
                                            htmlFor="exampleEmail2"
                                            className="ml-3"
                                        >
                                            Danh mục :
                                        </label>
                                    </Form.Label>
                                    <Col sm="10">
                                        <SelectionMutil
                                            data={lstCategoryMap}
                                            showCheckboxOnTree={true}
                                            EnableValidate={true}
                                            ErrorMessage={""}
                                            multiSelect={true}
                                            Placeholder="Danh mục"
                                            IconExpand={FaFolder}
                                            IconCollapse={FaFolderOpen}
                                            positionExpand="right"
                                            multiValueSelected={categoryValues}
                                            onChange={handleSelectCategory}
                                            ForceValidate={forceValidate}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Sửa
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
                <ReactSpinner loadings={isLoadingEdit} />
            </Card.Body>
        </Card>
    );
};

export default GiaodienEdit;
