import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {PARTNER} from '../constants';
import {Get} from '../../utils/httpProvider';
import {LOG_API} from '../../utils/apiUrl';

export const logPagingAction = createAsyncThunk(
    PARTNER.GET_PAGING,
    async (model) => {
        return Get(LOG_API.GET_PAGING_LOG, null, model)
            .then((response) => {
                return response;
            })
            .catch((error) => error);
    }
);

const initialState = {
    listLog: [],
    totalLog: 0
};
export const logSlice = createSlice({
    name: PARTNER.NAME_SLICE,
    initialState,
    reducers: {
        filterListLog: (state, action) => {
            state.listLog =
                state.listLog.length > 0
                    ? state.listLog.filter(
                          (x) =>
                              x.createdDate < action.payload.startDay &&
                              x.createdDate > action.payload.endDay
                      )
                    : [];
            state.totalLog = state.listLog.length;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logPagingAction.fulfilled, (state, action) => {
            state.listLog =
                action.payload &&
                action.payload.data &&
                action.payload.data.$values
                    ? action.payload.data.$values
                    : [];
            state.totalLog =
                action.payload && action.payload.totalRecords
                    ? action.payload.totalRecords
                    : 0;
        });
    }
});

export const {filterListLog} = logSlice.actions;

export default logSlice.reducer;
