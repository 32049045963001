import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    messageError,
    messageToast,
    objectRoles,
    typeError,
    styleSelectDefault,
    styleSelectError
} from '../../utils/define';
import {
    updateAccountAction,
    getByIdAccountAction
} from '../../store/reducers/account';

const schema = yup.object().shape({
    fullname: yup.string().required(messageError.emptyMessage),
    email: yup
        .string()
        .email(messageError.emailMessage)
        .required(messageError.emptyMessage),
    roles: yup.array().min(1, messageError.emptyMessage),
    password: yup
        .string()
        .min(6, messageError.minLength6Message)
        .max(30, messageError.maxLength30Message),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], messageError.likePassword)
        .when('password', {
            is: (value) => value && value.length > 0,
            then: yup.string().required(messageError.emptyMessage)
        })
});

const AccountEdit = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [idAccount, setIdAccount] = useState('');

    const itemAccount = useSelector((state) => state.account.itemAccount);
    const roleArrayString = itemAccount ? itemAccount.roles.split(', ') : [];
    const roleObjectString = [];
    if (roleArrayString.length > 0) {
        roleArrayString.forEach((element) => {
            const filteredValue = objectRoles.find(
                (item) => item.value === element
            );
            roleObjectString.push(filteredValue);
        });
    }

    useEffect(() => {
        const paramUrl = new URLSearchParams(location.search.substring(1));
        if (paramUrl.get('id') != null) {
            const id = paramUrl.get('id');
            setIdAccount(id);
            dispatch(getByIdAccountAction(id));
        }
    }, []);

    const handleUpdate = async (values, actions) => {
        const model = { ...values };
        const roleAccount =
            model.roles.length > 0 ? model.roles.map((x) => x.value) : [];
        model.roles = roleAccount;
        if (model.password === '' && model.confirmPassword === '') {
            delete model.password;
            delete model.confirmPassword;
        }

        const modelUpdate = {
            data: model,
            id: idAccount
        };

        try {
            const result = await dispatch(updateAccountAction(modelUpdate));
            if (result.payload.errors) {
                if (result.payload.type === typeError.Email) {
                    actions.setErrors({
                        email: result.payload.errors.content[0]
                    });
                }
                // toast.error(result.payload.errors.content[0]);
            }
            if (result.payload === 200) {
                toast.success(messageToast.messageToastUpdateSuccess);
                history.goBack();
            }
        } catch (error) {
            toast.error(messageToast.messageToastTryCatch);
        }
    };
    return (
        <Card>
            <Card.Header as="h5">Sửa thông tin tài khoản</Card.Header>
            <Card.Body>
                {itemAccount == null ? (
                    <div>Tài khoản không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values, actions) =>
                            handleUpdate(values, actions)
                        }
                        initialValues={{
                            fullname: itemAccount ? itemAccount.fullname : '',
                            email: itemAccount ? itemAccount.email : '',
                            roles: roleObjectString,
                            phoneNumber: itemAccount ? itemAccount.phoneNumber : '',
                            pseudonym: itemAccount ? itemAccount.pseudonym : '',
                            password: '',
                            confirmPassword: '',
                            isActive: itemAccount ? itemAccount.isActive : true,
                            isReceiveEmail: itemAccount ? itemAccount.isReceiveEmail : true,
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Họ tên *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Họ tên"
                                            name="fullname"
                                            value={values.fullname}
                                            onChange={handleChange}
                                            isInvalid={!!errors.fullname}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.fullname}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Email *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Số điện thoại
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Số điện thoại"
                                            name="phoneNumber"
                                            value={values.phoneNumber}
                                            onChange={handleChange}
                                        // isInvalid={!!errors.email}
                                        />
                                        {/* <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Bút danh
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder="Bút danh"
                                            name="pseudonym"
                                            value={values.pseudonym}
                                            onChange={handleChange}
                                        // isInvalid={!!errors.email}
                                        />
                                        {/* <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Danh sách quyền *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            isMulti
                                            name="roles"
                                            id="roles"
                                            placeholder="Chọn quyền cho tài khoản"
                                            value={values.roles}
                                            onChange={(selectedOption) => {
                                                setFieldValue(
                                                    'roles',
                                                    selectedOption,
                                                    true
                                                );
                                            }}
                                            options={objectRoles}
                                            styles={
                                                errors.roles !== undefined
                                                    ? styleSelectError
                                                    : styleSelectDefault
                                            }
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.roles}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mật khẩu *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="password"
                                            placeholder="Mật khẩu"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={!!errors.password}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Xác nhận mật khẩu *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="password"
                                            placeholder="Xác nhận mật khẩu"
                                            name="confirmPassword"
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                            isInvalid={!!errors.confirmPassword}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.confirmPassword}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                              
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái kích hoạt
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary1"
                                            type="checkbox"
                                            placeholder="Trạng thái"
                                            name="isActive"
                                            checked={values.isActive}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary1">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Đăng ký nhận Email đặt bàn
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="isReceiveEmail"
                                            type="checkbox"
                                            placeholder="Đăng ký nhận Email đặt bàn"
                                            name="isReceiveEmail"
                                            checked={values.isReceiveEmail}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="isReceiveEmail">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Cập nhật
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card.Body>
        </Card>
    );
};

export default AccountEdit;
